import React from 'react'
import { positiveParentingTips, healthyBodies, childSafetyFirst } from './shared'

export const AGE_IN_MONTHS = 1
export const pageTitle = 'Indicadores importantes: su bebé al 1 mes'
export const pageCopy = [
  {
    body: [
      <>
        Los hitos siguientes fueron desarrollados por la <a href='https://www.healthychildren.org/english/ages-stages/baby/pages/developmental-milestones-1-month.aspx'>American Academy of Pediatrics</a>.
      </>
    ]
  },
  {
    title: 'Logros importantes en sus movimientos',
    body: [
      [
        'Agita sus brazos de forma errática',
        'Lleva sus manos al alcance de sus ojos y boca',
        'Mueve la cabeza de lado a lado mientras está acostado boca abajo',
        'Deja caer su cabeza hacia atrás si no tiene apoyo',
        'Mantiene sus manos empuñadas fuertemente',
        'Movimientos de reflejo fuertes',
      ]
    ]
  },
  {
    title: 'Logros importantes visuales y de oído',
    body: [
      [
        'Enfoca a una distancia de 8 a 12 pulgadas (2,3 a 30,4 cm)',
        'Los ojos se desvían y en ocasiones se cruzan',
        'Prefiere patrones negros y blancos o de mucho contraste',
        'Prefiere los rostros humanos que todos los demás patrones',
        'El oído está totalmente madura',
        'Reconoce algunos sonidos',
        'Puede voltearse hacia sonidos y voces familiares',
      ]
    ]
  },
  {
    title: 'Logros importantes en el sentido del olfato y tacto',
    body: [
      [
        'Prefiere los olores dulces',
        'Evita los olores amargos y ácidos',
        'Reconoce el aroma de la leche materna de su mamá',
        'Prefiere las sensaciones suaves a las ásperas',
        'Le desagrada la manipulación fuerte o abrupta',
      ]
    ]
  },
  {
    title: 'Vigilancia de la salud del desarollo',
    body: [
      'Si, durante la segunda, tercera o cuarta semana de vida, su bebé muestra cualquiera de las siguientes señales de retraso en el desarrollo, notifique a su pediatra.',
      [
        'Succiona poco y come lentamente',
        'No parpadea cuando le muestra una luz brillante',
        'No enfoca ni sigue un objeto cercano que se mueve de lado a lado',
        'Rara vez mueve sus brazos y piernas; se sienten rígidas',
        'Sus extremidades parecen excesivamente flexibles o blandas',
        'La mandíbula inferior tiembla constantemente, aún cuando no está llorando ni entusiasmado',
        'No responde a sonidos fuertes',
      ]
    ]
  },
  positiveParentingTips(1),
  childSafetyFirst(1),
  healthyBodies(1),
]
