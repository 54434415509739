import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 18
export const pageTitle = 'Indicadores importantes: su bebé a los 18 meses'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final final de los 18 meses completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: 'Lo que la mayoría de los bebés hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Le gusta darles cosas a los demás como un juego <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20717/20717_lores.jpg']} /></>,
        <>Puede tener rabietas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/iSKMkHKhocQ']} /></>,
        <>Puede que les tenga miedo a los desconocidos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/0bUa6AvfaeQ']} /></>,
        <>Le demuestra afecto a las personas conocidas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20721/20721_lores.jpg']} /></>,
        <>Inventa juegos simples con su imaginación (por ejemplo, emite sonidos de animales con peluches)  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6kszHh5F4fU']} /></>,
        <>En situaciones nuevas, puede que se aferre a los que lo cuidan <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20718/20718_lores.jpg']} /></>,
        <>Señala para mostrarle a otras personas algo interesante <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20719/20719_lores.jpg']} /></>,
        <>Explora solo, pero con la presencia cercana de los padres <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20720/20720_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Puede decir varias palabras individuales  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/AMxgyI4EdSM']} /></>,
        <>Dice “no” y sacude la cabeza como negación  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/az4jhcbsDms']} /></>,
        <>Señala para mostrarle a otra persona lo que quiere  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/nmkZphuIzvk']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Sabe para qué sirven las cosas comunes; por ejemplo, teléfono, cepillo, cuchara  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20722/20722_lores.jpg']} /></>,
        <>Señala para llamar la atención de otras personas  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20723/20723_lores.jpg']} /></>,
        <>Demuestra interés en una muñeca o peluche y finge darle de comer <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/kL4-4Ls0W7Q']} /></>,
        <>Señala una parte del cuerpo  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20724/20724_lores.jpg']} /></>,
        <>Hace garabatos por sí solo  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/RSynaBnWz4k']} /></>,
        <>Puede seguir instrucciones verbales de un solo paso sin que se le haga ningún gesto  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20725/20725_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Camina solo  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/JaOUmM5ItYU']} /></>,
        <>Puede subir escalones y correr  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/E5zpJPaxm18']} /></>,
        <>Arrastra juguetes detrás de él mientras camina  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/pJ6lJT0OakY']} /></>,
        <>Puede ayudar a desvestirse  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6D4MyzTdyTE']} /></>,
        <>Bebe de una taza  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20727/20727_lores.jpg']} /></>,
        <>Come con cuchara  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20726/20726_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        "No señala cosas para mostrárselas a otras personas",
        "No puede caminar",
        "No sabe para qué sirven las cosas familiars",
        "No copia lo que hacen las demás persona",
        "No aprende nuevas palabras",
        "No sabe por lo menos 6 palabras",
        "No se da cuenta ni parece importarle si la persona que le cuida se va a o regresa",
        "Pierde habilidades que había adquirido",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
