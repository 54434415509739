import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 48
export const pageTitle = 'Important Milestones: Your child by 4 Years'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 4 years by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Enjoys doing new things <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Gr9KyV1xKBQ']} /></>,
        <>Plays “Mom” and “Dad” <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20764/20764_lores.jpg']} /></>,
        <>Is more and more creative with make-believe play <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/t14sEYj8kAQ']} /></>,
        <>Would rather play with other children than by himself <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/ZsB56sZP95g']} /></>,
        <>Cooperates with other children <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20765/20765_lores.jpg']} /></>,
        <>Often can’t tell what’s real and what’s make-believe <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/t_XG_3FSh68']} /></>,
        <>Talks about what she likes and what she is interested in <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6yWd2dDF2nU']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Knows some basic rules of grammar, such as correctly using “he” and “she” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/hEHvgGS0B3Y']} /></>,
        <>Sings a song or says a poem from memory such as the “Itsy Bitsy Spider” or the “Wheels on the Bus” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Z4cKlVpzAbc']} /></>,
        <>Tells stories <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/iUTtaGVC8PY']} /></>,
        <>Can say first and last name</>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Names some colors and some numbers <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/s6fmOSMzxY8']} />&nbsp;&nbsp;<ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/q7gVDZbT6QA']} /></>,
        <>Understands the idea of counting <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/e9U3qMmpd3E']} /></>,
        <>Starts to understand time <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/SYBOIxJTbQM']} /></>,
        <>Remembers parts of a story <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/f-QCjY5caTw']} /></>,
        <>Understands the idea of “same” and “different” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/wjT7MGW0TnI']} /></>,
        <>Draws a person with 2 to 4 body parts <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20766/20766_lores.jpg']} /></>,
        <>Uses scissors <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20767/20767_lores.jpg']} /></>,
        <>Starts to copy some capital letters <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/c2v0jzIrNjE']} /></>,
        <>Plays board or card games <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20768/20768_lores.jpg']} /></>,
        <>Tells you what he thinks is going to happen next in a book <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/d92mzpawjAE']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Hops and stands on one foot up to 2 seconds <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20769/20769_lores.jpg']} /></>,
        <>Catches a bounced ball most of the time <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20770/20770_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20771/20771_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20772/20772_lores.jpg']} /></>,
        <>Pours, cuts with supervision, and mashes own food <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20773/20773_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        "Can’t jump in place",
        "Has trouble scribbling",
        "Shows no interest in interactive games or make-believe",
        "Ignores other children or doesn’t respond to people outside the family",
        "Resists dressing, sleeping, and using the toilet",
        "Can’t retell a favorite story",
        "Doesn’t follow 3-part commands",
        "Doesn’t understand “same” and “different”",
        "Doesn’t use “me” and “you” correctly",
        "Speaks unclearly",
        "Loses skills he once had",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
