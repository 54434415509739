import React from "react";

export const pageTitle = "Developmental Screening";
export const pageCopy = [
  {
    body: [
      "Developmental screening takes a closer look at how your child is developing. Your child will get a brief test, or you will complete a questionnaire about your child.",
    ],
  },
  {
    title: "Who does Developmental Screening?",
    body: [
      "Developmental screening can be done by a doctor or nurse, but also by other professionals in healthcare, early childhood education, community, or school settings.",
      <>
        Additionally, if you are concerned about your child’s development, call{" "}
        <a href="https://helpmegrow.dc.gov/">
          Help Me Grow District of Columbia (HMG DC)
        </a>{" "}
        at 1-800-MOM-BABY (1-800-666-2229) and speak with a care coordinator.
        HMG DC offers the Ages and Stages Questionnaire (ASQ) to families in the
        District. There are two ASQ screenings available: general and
        social-emotional development.
      </>,
    ],
  },
  {
    title: "When should Developmental Screening be done?",
    body: [
      "Your child should be screened if you or your doctor have a concern. However, developmental screening is a regular part of some of the well-child visits for all children even if there is not a known concern.",
      "The American Academy of Pediatrics (AAP) recommends developmental and behavioral screening for all children during regular well-child visits at these ages:",
      ["9 months", "18 months", "30 months"],
      "In addition, AAP recommends that all children be screened specifically for autism spectrum disorder (ASD) during regular well-child visits at:",
      ["18 months", "24 months"],
      "You can use a screening passport, to keep track of your child’s screening history and results.",
    ],
  },
  {
    title: "What if my child is not being screened?",
    body: [
      "If your child’s healthcare provider does not periodically check your child with a developmental screening test, you can ask that it be done.",
      "If your child’s doctor has told you to “wait and see,” but you feel uneasy about that advice:",
      [
        "Talk with another doctor to get a second opinion, AND",
        "Call for a free evaluation to find out if your child can get free services that can help.",
      ],
      <>
        Contact DC’s Child Find programs,{" "}
        <a href="https://osse.dc.gov/page/osse-your-language">
          Strong Start DC Early Intervention Program
        </a>{" "}
        (if your child is age 0-3 years) or{" "}
        <a href="https://www.earlystagesdc.org">Early Stages</a> (if your child
        is between 2 years, 8 month and 5 years 10 months).
      </>,
    ],
  },
  {
    title: "What Happens if Developmental Screening Raises Concerns?",
    body: [
      <>
        There are many different types of screening tools. Screening tools for
        general developmental concerns are sometimes called “Level 1 Screening
        Tools.” Screening tools for specific developmental concerns (e.g.,
        autism symptoms), are sometimes called “Level 2 Screening Tools.” If a
        Level 1 Screening Tool identifies a problem, your child’s healthcare
        provider may use a Level 2 Screening Tool. If a Level 2 Screening Tool
        identifies a problem, they may refer your child for further
        evaluation(s). Note that sometimes providers will directly refer for an{" "}
        <a href="https://actearlydc.org/evaluations?language=en">evaluation</a>{" "}
        after a Level 1 Screening Tool.
      </>,
    ],
  },
];
