const ES_MONTHS = {
  1: '1 Mes',
  2: '2 Meses',
  3: '3 Meses',
  4: '4 Meses',
  6: '6 Meses',
  9: '9 Meses',
  12: '1 Año',
  18: '18 Meses',
  24: '2 Años',
  36: '3 Años',
  48: '4 Años',
  60: '5 Años',
}

const EN_MONTHS = {
  1: '1 Month',
  2: '2 Months',
  3: '3 Months',
  4: '4 Months',
  6: '6 Months',
  9: '9 Months',
  12: '1 Year',
  18: '18 Months',
  24: '2 Years',
  36: '3 Years',
  48: '4 Years',
  60: '5 Years',
}

export const pages = i18n => {
  const copy = i18n === 'en' ? EN_MONTHS : ES_MONTHS

  return ([
    {
      ageInMonths: 1,
      label: copy[1],
      path: '1_month',
    },
    {
      ageInMonths: 2,
      label: copy[2],
      path: '2_months',
    },
    {
      ageInMonths: 3,
      label: copy[3],
      path: '3_months',
    },
    {
      ageInMonths: 4,
      label: copy[4],
      path: '4_months',
    },
    {
      ageInMonths: 6,
      label: copy[6],
      path: '6_months',
    },
    {
      ageInMonths: 9,
      label: copy[9],
      path: '9_months',
    },
    {
      ageInMonths: 12,
      label: copy[12],
      path: '12_months',
    },
    {
      ageInMonths: 18,
      label: copy[18],
      path: '18_months',
    },
    {
      ageInMonths: 24,
      label: copy[24],
      path: '2_years',
    },
    {
      ageInMonths: 36,
      label: copy[36],
      path: '3_years',
    },
    {
      ageInMonths: 48,
      label: copy[48],
      path: '4_years',
    },
    {
      ageInMonths: 60,
      label: copy[60],
      path: '5_years',
    }
  ])
}
