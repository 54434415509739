import React from 'react'
import developmentalMonitoring from '/src/images/developmental_monitoring.jpeg'
import developmentalScreening from '/src/images/developmental_screening.jpeg'
import referralAndEvaluation from '/src/images/referral_and_evaluation.jpeg'
import autismResources from '/src/images/autism_resources.jpeg'
import earlyIntervention from '/src/images/early_intervention.jpeg'
import developmentalMilestones from '/src/images/developmental_milestones.jpeg'
import toolkitCard from '/src/images/toolkit_card.jpeg'
// import earlyStages from '/src/images/early_stages.jpeg'
import virtualEvent from '/src/images/virtual_event.jpeg'
import milestonesApp from '/src/images/milestones_app.jpeg'
import freeMaterials from '/src/images/free_materials.jpeg'

export const pageTitle = 'Learn The Signs. Act Early.'
export const paragraphs = [
  "As a parent, you already have what it takes to help your young child learn and grow.  From birth to 5 years, your child should reach milestones in how he or she plays, learns, speaks, acts and moves.  As you watch your child’s amazing progress, track his or her development, share that progress with your child’s doctor at every check-up, and act early if you have a concern.",
  <>
  Act Early DC is the District Of Columbia's local campaign of the national Centers for Disease Control and Prevention’s <a href='http://www.cdc.gov/actearly'>"Learn the Signs. Act Early."</a> (LTSAE) Program.  LTSAE aims to improve early identification of children with autism and other developmental disabilities so children and families can get the services and support they need.
  </>
]

export const cards = [
  {
    header: 'Developmental Monitoring',
    content: 'Observe how your child grows and changes over time and whether your child meets the typical developmental milestones in playing, learning, speaking, behaving, and moving using milestone checklists.',
    path: '/monitoring',
    imageSrc: developmentalMonitoring 
  },
  {
    header: 'Developmental Milestones',
    content: 'Skills such as taking a first step, smiling for the first time, and waving “bye bye” are called developmental milestones. Children reach milestones in how they play, learn, speak, act, and move (crawling, walking, etc.).',
    path: '/milestones',
    imageSrc: developmentalMilestones
  },
  {
    // update this image
    header: 'CDC Milestone Tracker App',
    content: 'Get tips from the CDC for encouraging your child’s development; and find out what to do if you are ever concerned about how your child is developing.',
    url: 'https://www.cdc.gov/ncbddd/actearly/milestones-app.html',
    imageSrc: milestonesApp
  },
  {
    header: 'Free Materials',
    content: 'The CDC has created free materials for parents to track their child’s development.  The materials include children’s books, milestone checklists, growth charts, tips on how to get help for your child, and more.',
    url: 'https://www.cdc.gov/ncbddd/actearly/freematerials.html',
    imageSrc: freeMaterials
  },
  {
    header: 'Developmental Screening',
    content: 'To find out if you child needs more help with development, your child will get a brief test, or you will complete a questionnaire about your child.',
    path: '/screening',
    imageSrc: developmentalScreening
  },
  {
    header: 'Referral and Evaluations',
    content: 'If the screening tool identifies an area of concern, you will be referred to a trained specialist to find out if your child needs specific treatment or qualifies for early intervention.',
    path: '/evaluations',
    imageSrc: referralAndEvaluation 
  },
  {
    header: 'Early Intervention Services',
    content: 'Services and supports that are available to babies and young children with developmental delays and disabilities and their families.',
    path: '/early_intervention_services',
    imageSrc: earlyIntervention 
  },
  {
    header: 'Autism Resources',
    content: 'If you suspect your child may have an autism spectrum disorder (ASD) or is newly diagnosed with ASD, check out these resources.',
    path: '/autism_resources',
    imageSrc: autismResources
  },
  {
    header: 'Act Early DC Parent Toolkit',
    content: 'The Act Early DC Parent Toolkit provides tips on how families can support their young children during the COVID-19 Pandemic and beyond.',
    path: '/parent_resources',
    imageSrc: toolkitCard
  },
  {
    header: 'Act Early DC Virtual Family Event',
    content: 'In May 2021, the Act Early DC COVID-19 Response Team hosted a virtual event for families and caregivers of children ages 0-5 years to support their child’s development and self-care for themselves. Check out the session here!',
    url: 'https://www.youtube.com/playlist?list=PLEhZQU_WEgEu_iLBwqugcpp6obfDbjqjF',
    imageSrc: virtualEvent
  },
]
