import React from 'react'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 3
export const pageTitle = 'Important Milestones: Your baby by Three Months'
export const pageCopy = [
  {
    body: [
      <>
        The following milestones were developed by the <a href='https://www.healthychildren.org/english/ages-stages/baby/pages/developmental-milestones-1-month.aspx'>American Academy of Pediatrics</a>.
      </>
    ]
  },
  {
    title: 'Movement Milestones',
    body: [
      [
        'Raises head and chest when lying on stomach',
        'Supports upper body with arms when lying on stomach',
        'Stretches legs out and kicks when lying on stomach or back',
        'Opens and shuts hands',
        'Pushes down on legs when feet are placed on a firm surface',
        'Brings hand to mouth',
        'Takes swipes at dangling objects with hands',
        'Grasps and shakes hand toys',
      ]
    ]
  },
  {
    title: 'Visual and Hearing Milestones',
    body: [
      [
        'Watches faces intently',
        'Follows moving objects',
        'Recognizes familiar objects and people at a distance',
        'Starts using hands and eyes in coordination',
        'Smile at the sound of your voice',
        'Begins to babble',
        'Begins to imitate some sounds',
        'Turns head toward direction of sound',
      ]
    ]
  },
  {
    title: 'Social and Emotional Milestones',
    body: [
      [
        'Begins to develop a social smile',
        'Enjoys playing with other people and may cry when playing stops',
        'Becomes more talkative and expressive with face and body',
        'Imitates some movements and facial expressions',
      ]
    ]
  },
  {
  title: 'Developmental Health Watch',
  body: [
    'Although each baby develops in her own individual way and at her own rate, failure to reach certain milestones may signal medical or developmental problems requiring special attention. If you notice any of the following warning signs in your infant at this age, discuss them with your pediatrician',
    [
      'Doesn’t seem to respond to loud sounds',
      'Doesn’t notice her hands by two months',
      'Doesn’t smile at the sound of your voice by two months',
      'Doesn’t follow moving objects with her eyes by two to three months',
      'Doesn’t grasp and hold objects by three months',
      'Doesn’t smile at people by three months',
      'Cannot support her head well at three months',
      'Doesn’t reach for and grasp toys by three to four months',
      'Doesn’t babble by three to four months',
      'Doesn’t bring objects to her mouth by four months',
      'Begins babbling, but doesn’t try to imitate any of your sounds by four months',
      'Doesn’t push down with her legs when her feet are placed on a firm surface by four months',
      'Has trouble moving one or both eyes in all directions' ,
      'Crosses her eyes most of the time (Occasional crossing of the eyes is normal in these first months.)',
      'Doesn’t pay attention to new faces, or seems very frightened by new faces or surroundings',
      'Still has the tonic neck reflex at four to five months',
    ]
  ]
},
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS)
]
