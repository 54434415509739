import React from "react";

export const pageTitle = "Recursos sobre el Autismo";
export const pageCopy = [
  {
    title: "¿Qué es el autismo?",
    body: [
      <>
        El autismo o trastorno del espectro autista (TEA) es una discapacidad
        del desarrollo neurológico la cual está relacionada con dificultades
        sociales, de comunicación y de comportamiento. Los niños diagnosticados
        con autismo pueden comunicarse, interactuar, comportarse y aprender de
        maneras diferentes a las de las personas sin autismo. Puede acceder a la
        página web de autismo de los{" "}
        <a href="https://www.cdc.gov/ncbddd/autism/facts.html">
          Centros para el control y prevención de enfermedades (CDC)
        </a>{" "}
        apara obtener más información sobre cómo se define el autismo.
      </>,
    ],
  },
  {
    title: "¿Qué tan común es el autismo?",
    body: [
      "Los Centros para el control y prevención de enfermedades (CDC, por sus siglas en inglés) estiman que a aproximadamente 1 de cada 44 niños de 8 años se le ha detectado autismo. Puede consultar el Informe comunitario 2021 de la Red de monitoreo de autismo y discapacidades del desarrollo para obtener más información.",
    ],
  },
  {
    title: "¿Cómo se diagnostica el autismo?",
    body: [
      <>
        El diagnóstico de autismo se basa en la descripción del desarrollo de su
        hijo, una revisión del historial de su hijo y las observaciones de
        ciertos comportamientos por parte del proveedor de atención primaria de
        su hijo y de un experto en autismo. Puede obtener más información
        leyendo el artículo de la Academia americana de pediatría,{" "}
        <a href="https://www.healthychildren.org/English/health-issues/conditions/Autism/Pages/Diagnosing-Autism.aspx?_ga=2.61781861.877485078.1632228743-34439519.1632228743&_gl=1*tnq54o*_ga*MzQ0Mzk1MTkuMTYzMjIyODc0Mw..*_ga_FD9D3XZVQQ*MTYzMjIyODc0Mi4xLjEuMTYzMjIyODkxMi4w">
          ¿Cómo se diagnostica el autismo?"
        </a>
      </>,
      <b>Los criterios para diagnosticar el autismo incluyen:</b>,
      [
        "dificultades con la comunicación e interacción social",
        "comportamientos o intereses restringidos o repetitivos",
      ],
    ],
  },
  {
    title: "¿Cuáles son las primeras señales del autismo?",
    body: [
      <>
        Es importante tener en cuenta que la mayoría de los niños con autismo
        solo desarrollan algunos, pero no todos los comportamientos mencionados.
        Los CDC tienen una lista de las señales del autismo, la cual se puede
        encontrar{" "}
        <a href="https://www.cdc.gov/ncbddd/autism/signs.html">aquí.</a>
      </>,
      <>
        La Academia americana de pediatría tiene una lista similar,{" "}
        <a href="https://www.healthychildren.org/English/health-issues/conditions/Autism/Pages/Early-Signs-of-Autism-Spectrum-Disorders.aspx">
          ¿Cuáles son las primeras señales del autismo?"
        </a>{" "}
        esta incluye ejemplos de cómo diferenciar entre un niño con autismo y un
        niño con desarrollo típico. Autism Navigator desarrolló{" "}
        <a href="https://babynavigator.com/16-early-signs-of-autism-by-16-months-chinese/#fb0=125">
          el Lookbook de Baby Navigator: 16 señales tempranas del autismo a los
          16 meses.
        </a>
      </>,
      "El Instituto Kennedy Krieger ha desarrollado un video tutorial de 9 minutos para mejorar el reconocimiento de las primeras señales del autismo. El tutorial consta de 6 videoclips que comparan a niños pequeños que no muestran señales de autismo con niños pequeños que muestran las primeras señales de autismo.",
    ],
  },
  {
    title: "Si sospecho que tiene autismo, ¿qué debo hacer ahora?",
    body: [
      <>
        Si sospecha de autismo, hay varias opciones para los siguientes pasos. A
        menudo es útil seguir varias rutas al mismo tiempo. Esto incluye buscar
        una intervención temprana o una evaluación educativa (Strong Start o
        Early Stages). Busque una evaluación en el entorno médico. Hable con el
        proveedor de atención primaria de su hijo acerca de estas opciones. Si
        el proveedor de atención primaria de su hijo le ha dicho que "espere y
        vea", pero se siente incómodo con ese consejo, puede autorreferirse a
        <a href="https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip">
          Strong Start
        </a>{" "}
        o <a href="https://www.earlystagesdc.org/">Early Stages</a>
      </>,
    ],
  },
  {
    title: "Intervención temprana/ Evaluaciones educativas",
    body: [
      <>
        Guiados por la{" "}
        <a href="https://sites.ed.gov/idea/statute-chapter-33/subchapter-iii/1436 ">
          Ley para la educación de individuos con discapacidades (IDEA) Parte C
        </a>{" "}
        y
        <a href="https://sites.ed.gov/idea/statute-chapter-33/subchapter-ii/1414">
          Parte B
        </a>
        ,{" "}
        <a href="https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip">
          Strong Start
        </a>{" "}
        y <a href="https://www.earlystagesdc.org/ ">Early Stages</a> son
        gratuitos para todas las familias del Distrito de Columbia. Se realiza
        una evaluación para determinar si el niño es elegible para los
        servicios.
      </>,
      [
        <>
          Para niños desde el nacimiento hasta los 2 años y 10 meses{" "}
          <a href="https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip">
            Strong Start
          </a>{" "}
          proporciona servicios a través de un Plan de servicios familiares
          individualizados (IFSP, por sus siglas en inglés) si hay un retraso
          del 25% en 1 a 5 áreas del desarrollo. Aunque no diagnostican ni
          clasifican a los niños como autistas, brindan servicios de
          intervención importantes que muchos niños con autismo necesitan, como
          terapia del lenguaje, terapia ocupacional y análisis conductual
          aplicado.
        </>,
        <>
          Para niños de 2 años y 8 meses a 5 años y 10 meses, las evaluaciones
          de Early Stages se utilizan para determinar si un niño cumple con los
          criterios para una de las 14 discapacidades especificadas por IDEA,
          las cuales incluyen el autismo.{" "}
          <a href="https://www.earlystagesdc.org/">Early Stages</a> creará y
          recomendará servicios de un plan de educación individualizado (IEP,
          por sus siglas en inglés). Los servicios del IEP se implementan en el
          entorno de una escuela pública de DC o en una escuela pública chárter
          de DC.
        </>,
      ],
    ],
  },
  {
    title: "Evaluaciones de autismo en un entorno médico",
    body: [
      <>
        Los niños pueden recibir una evaluación integral de autismo en un
        entorno médico por parte de un proveedor médico (pediatra, psicólogo u
        otro especialista médico). Estas evaluaciones generalmente están
        cubiertas por el seguro médico de su hijo. Los proveedores médicos se
        basan en su descripción del desarrollo de su hijo, el historial de
        desarrollo, las observaciones propias y las evaluaciones para determinar
        si su hijo cumple con los{" "}
        <a href="https://www.cdc.gov/ncbddd/autism/hcp-dsm.html">
          Criterios de diagnóstico del DSM-5 para el TEA.
        </a>{" "}
        Si su hijo cumple con estos criterios, el proveedor le dará a su hijo un{" "}
        <b>diagnóstico médico de autismo.</b>
      </>,
    ],
  },
  {
    title:
      "¿Cuáles son las diferencias entre un diagnóstico médico y una clasificación educativa de autismo?",
    body: [
      <>
        Por lo general, el diagnóstico médico de autismo lo lleva a cabo un
        proveedor médico en un entorno de atención médica empleando los
        <a href="https://www.cdc.gov/ncbddd/autism/hcp-dsm.html">
          Criterios de diagnóstico del DSM-5
        </a>
        , este diagnóstico no siempre conlleva a la elegibilidad para los
        servicios de intervención temprana/educación especial. Un diagnóstico
        médico de autismo puede ser útil para garantizar que un niño tenga mayor
        acceso a servicios a través de su plan de seguro médico. Un proveedor de
        atención médica puede recomendar otras terapias y servicios que no
        forman parte del IFSP o del IEP.
      </>,
      "Para niños de 2 años y 8 meses de edad en adelante, una clasificación educativa de autismo identifica el autismo como una discapacidad bajo la ley IDEA y determina la elegibilidad para los servicios de educación especial. Una clasificación educativa del autismo puede ser útil para garantizar que el niño tenga acceso a los apoyos necesarios en la escuela.",
    ],
  },
  {
    title:
      "¿Por qué es importante buscar un diagnóstico médico y una clasificación educativa de autismo?",
    body: [
      "Las familias pueden tener un mejor acceso a los servicios en la escuela y en la comunidad y pueden apoyar el desarrollo de sus hijos de manera integral.",
    ],
  },
  {
    title: "¿Cómo accedo a la intervención temprana/evaluaciones educativas?",
    body: [
      "El proveedor de atención primaria de su hijo puede derivarlo o usted puede autorreferirse a Strong Start o intervención temprana.",
      [
        <>
          Si su hijo tiene una edad comprendida entre el nacimiento hasta los 2
          años y 10 meses: Llame a la línea directa de{" "}
          <a href="https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip">
            Strong Start, el Programa de intervención temprana de DC
          </a>{" "}
          al (202) 727-3665 o llene el formulario de referencia (hyperlink:
          https://eip.osse.dc.gov/ReferralForm/Create)
        </>,
        <>
          Si su hijo tiene una edad comprendida entre los 2 años y 8 meses y los
          5 años y 10 meses: Comuníquese con{" "}
          <a href="https://www.earlystagesdc.org/">Early Stages</a> al (202)
          698-8037 o envíe un{" "}
          <a href="https://www.earlystagesdc.org/form/refer-a-child">
            formulario de referencia aquí.
          </a>
        </>,
      ],
      "Tenga en cuenta que el proceso de evaluación de intervención temprana/educación especial solo puede comenzar después de que un padre/tutor haya dado su consentimiento por escrito.",
    ],
  },
  {
    title:
      "¿DÓNDE PUEDO SOLICITAR QUE EVALÚEN A MI HIJO PARA UN DIAGNÓSTICO MÉDICO DE AUTISMO? ",
    body: [
      "Puede llamar a su aseguradora o pedirle al médico de su hijo que le ayude a encontrar un proveedor de atención médica que pueda diagnosticar el autismo. A continuación, también se muestra una lista de clínicas que evalúan el autismo:",
      <b>Área Metropolitana de DC:</b>,
      [
        <>
          <a href="https://childrensnational.org/healthcare-providers/refer-a-patient/clinic-guidelines/autism-and-neurodevelopmental-referral-guidelines">
            Children’s National Hospital: varias clínicas de autismo
          </a>
        </>,
        <>
          <a href="https://www.medstarhealth.org/services/autism-and-communications-disorder-clinic">
            MedStar Georgetown University Hospital: clínica de autismo y
            trastornos de la comunicación
          </a>{" "}
          202-444-2722
        </>,
      ],
      <b>Área de Baltimore:</b>,
      [
        <>
          <a href="https://www.kennedykrieger.org/patient-care/centers-and-programs/center-for-autism-and-related-disorders">
            Centro para el autismo y trastornos relacionados en el Instituto
            Kennedy Krieger
          </a>{" "}
          443-923-7630, option 2
        </>,
        <>
          <a href="https://www.mwph.org/health-services/behavioral-health/autism-spectrum-center">
            Centro del espectro autista en Mount Washington Pediatric Hospital
          </a>{" "}
          410-367-2222
        </>,
      ],
    ],
  },
  {
    title:
      "Consejos para padres sobre cómo acceder a las evaluaciones de autismo:",
    body: [
      [
        "Las listas de espera son largas para las evaluaciones médicas (de 3 meses a 2 años), pero no se desanime ",
        "Llame a varias clínicas y envíe los documentos, siempre puede llamar para cancelar una vez que obtenga una cita ",
        "La evaluación médica y las evaluaciones educativas/de intervención temprana pueden realizarse al mismo tiempo, y a menudo es una buena idea realizar ambas al mismo tiempo ",
        "Haga preguntas si no entiende ",
        "Trabaje en conjunto con su proveedor de atención médica para organizar evaluaciones de diagnóstico adicionales si es necesario",
      ],
    ],
  },
  {
    title:
      "Si necesita ayuda para acceder o navegar por estos servicios, comuníquese con:",
    body: [
      [
        <a href="https://www.aje-dc.org/">
          Advocates for Justice and Education
        </a>,
        <a href="https://childrenslawcenter.org/">Children’s Law Center</a>,
        <a href="https://www.dcautismparents.org/">DC Autism Parents</a>,
      ],
      <>
        La Academia americana de pediatría ha creado un recurso,{" "}
        <a href="https://www.healthychildren.org/English/health-issues/conditions/Autism/Pages/If-Autism-is-Suspected-Whats-Next.aspx?_ga=2.125948740.877485078.1632228743-34439519.1632228743&_gl=1*111y09d*_ga*MzQ0Mzk1MTkuMTYzMjIyODc0Mw..*_ga_FD9D3XZVQQ*MTYzMjIyODc0Mi4xLjEuMTYzMjIyODk1MS4w">
          Si se sospecha autismo, ¿qué sigue?"
        </a>{" "}
        el cual proporciona información sobre lo que sucede durante una
        evaluación de autismo, así como una lista de exámenes médicos que no se
        recomiendan.
      </>,
    ],
  },
  {
    title: "¿Qué debo hacer después de una evaluación?",
    body: [
      <>
        Los padres deben mantener registros de las conversaciones y
        comunicaciones con los proveedores y las agencias. El Centro de
        Información y Recursos para Padres creó esta{" "}
        <a href="https://www.parentcenterhub.org/wp-content/uploads/repo_items/recordkeeping.pdf">
          hoja de registro de muestra
        </a>
        . Los padres deben obtener una copia de la evaluación y compartirla con
        los proveedores de atención médica y educación de sus hijos.
      </>,
      <>
        También será importante que se relacione con otros padres/cuidadores de
        niños diagnosticados con autismo y se conecte con los recursos de la
        comunidad. Eche un vistazo a esta publicación de blog titulada{" "}
        <a href="https://www.dcautismparents.org/blog/the-first-three-things-to-do-when-your-child-is-diagnosed-with-autism">
          Las primeras tres cosas que debe hacer cuando su hijo es diagnosticado
          con autismo"
        </a>{" "}
        para ayudarle a empezar.
      </>,
    ],
  },
  {
    title: "Recursos de autismo para familias:",
    body: [
      <>
        <a href="https://www.dcautismparents.org/">DC Autism Parents (DCAP)</a>{" "}
        es una organización fundada por padres de niños con autismo y operada
        por padres y voluntarios como una organización sin fines de lucro
        501(c)3. DCAP ofrece un boletín mensual, llamadas del grupo de apoyo y
        capacitación para las familias. Para suscribirse a nuestro boletín, haga
        clic <a href="https://lp.constantcontactpages.com/su/GFlfc5x">aquí.</a>
      </>,
      <>
        <b>Centro de capacitación e información para padres</b> Cada estado
        tiene al menos un Centro de capacitación e información para padres (PTI,
        por sus siglas en inglés) financiado según la Ley para la educación de
        individuos con discapacidades (IDEA, por sus siglas en inglés). El
        objetivo del PTI es apoyar a las familias de niños con discapacidades
        desde el nacimiento hasta los 22 años al proporcionar información
        gratuita para maximizar la educación de sus hijos. El PTI de DC es{" "}
        <a href="https://www.aje-dc.org/">Advocates for Justice & Education</a>
      </>,
      <>
        <b>Centro de información de salud de familia a familia (F2F)</b>{" "}
        <a href="https://www.aje-dc.org/">
          Advocates for Justice & Education (AJE)
        </a>{" "}
        ies el F2F de DC, los cuales son centros dirigidos por familias
        financiados por la Administración de recursos y servicios de salud. Hay
        un F2F en cada estado, y cada F2F cuenta con miembros de las familias
        altamente calificados y con conocimientos, quienes tienen experiencia y
        comprensión de primera mano sobre los desafíos que enfrentan las
        familias de niños y jóvenes con necesidades especiales de atención
        médica.
      </>,

      <>
        <b>Parent to Parent (P2P):</b> Parent to Parent USA (padre a padre)es
        una red nacional de programas P2P para garantizar el acceso a apoyo
        emocional de calidad para las familias de personas con discapacidades
        y/o necesidades especiales de atención médica. A través de un
        "emparejamiento" de uno a uno, los padres de apoyo experimentados
        brindan apoyo emocional a las familias de personas con necesidades
        especiales de atención médica y los ayudan a encontrar información y
        recursos.{" "}
        <a href="https://www.dcqualitytrust.org/family-ties/">
          Family Ties of DC
        </a>{" "}
        es el programa de padres a padres para los padres y cuidadores de los
        niños con discapacidades del Distrito de Columbia.
      </>,
      <>
        <b>Developmental Disabilities (DD) Council:</b>{" "}
        <a href="https://ddc.dc.gov/">El DC DD Council</a>
        (Consejo de Discapacidades del Desarrollo de DC) es un comité asesor
        independiente, basado en la comunidad, financiado por el Departamento de
        Salud y Servicios Humanos de los EE.UU., Administración de
        Discapacidades Intelectuales y del Desarrollo. El DD Council busca
        fortalecer la voz de las personas con discapacidades del desarrollo y
        sus familias para apoyar una mayor independencia, inclusión,
        empoderamiento y la búsqueda de la vida que elijan.
      </>,
      <>
        <b>Socios en la formulación de políticas (PIP):</b> El PIP es un
        programa que prepara a adultos con discapacidades intelectuales/del
        desarrollo (I/DD), padres y familiares de niños y adultos con I/DD para
        que sean defensores efectivos a nivel local, estatal y federal. Este
        programa está disponible actualmente en más de 30 estados, incluyendo
        DC.{" "}
        <a href="https://dcpartners.iel.org/">DC Advocacy Partners (DC AP)</a>{" "}
        ies el programa PIP de DC. DC AP es un programa de capacitación en
        liderazgo diseñado para defensores de sí mismos y familiares de personas
        con discapacidades intelectuales y/o del desarrollo, así como para
        profesionales que trabajan con personas con discapacidad.
      </>,
    ],
  },
];
