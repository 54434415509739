import React, { useContext } from 'react'
import { SimplePage } from '/src/components/SimplePage'
import * as en from '/src/locales/en/autism_resources'
import * as es from '/src/locales/es/autism_resources'
import { GlobalContext } from '/src/components/GlobalContext'

const AutismResources = ({ }) => {
  const { i18n } = useContext(GlobalContext)

  return (
    <>
      <SimplePage
        title={i18n === 'en' ? en.pageTitle : es.pageTitle}
        copy={i18n === 'en' ? en.pageCopy : es.pageCopy}
      />
    </>
  )
}

export default AutismResources
