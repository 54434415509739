import React, { useContext } from 'react'
import * as en from '/src/locales/en/milestones'
import * as es from '/src/locales/es/milestones'
import { GlobalContext } from '/src/components/GlobalContext'
import { BabyImageLink } from '/src/components/BabyImageLink';

const Milestones = ({}) => {
  const { i18n } = useContext(GlobalContext)
  const copy = i18n === 'en' ? en : es

  return (
    <>
      <section className="section">
        <h1 className="title is-3 has-text-primary is-family-sans-serif pb-4">{copy.pageTitle}</h1>
        <h2 className="content">
          {copy.pageBody}
        </h2>
      </section>
      <div className='is-flex is-justify-content-space-around is-flex-wrap-wrap'>
        {copy.BabyImageLink.map(data => (
          <BabyImageLink
            linkTo={data.linkTo}
            imageSrc={data.imageSrc}
            header={data.header}
          />
        ))}
      </div>
    </>
  );
}

export default Milestones;
