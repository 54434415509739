import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 24
export const pageTitle = 'Indicadores importantes: su bebé a los 2 años'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final de los 2 años completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: 'Lo que la mayoría de los bebés hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Imita a otras personas, especialmente a los adultos y a los niños mayores <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20728/20728_lores.jpg']} /></>,
        <>Se entusiasma cuando está con otros niños <ExternalLink icon={faCamera} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/images/getsExcitedWhenWithChildren.jpg']} /></>,
        <>Demuestra ser cada vez más independiente <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20729/20729_lores.jpg']} /></>,
        <>Demuestra un comportamiento desafiante (hace lo que se le ha dicho que no haga)  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/HaXnKsnlohk']} /></>,
        <>Juega principalmente al lado de otros niños, aunque empieza a incluirlos en juegos como correr y perseguirse  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/aau2i8hIQaY']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Señala objetos o ilustraciones cuando alguien los menciona  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/o9vfRS7x53c']} /></>,
        <>Sabe los nombres de personas conocidas y partes del cuerpo  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/o-icLJC6tHc']} /></>,
        <>Dice frases de 2 a 4 palabras  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/rIdCA-imsZU']} /></>,
        <>Sigue instrucciones sencillas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/enEGavf5Cn8']} /></>,
        <>Repite palabras que escuchó en alguna conversación <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/7WoNafM_RIk']} /></>,
        <>Señala las cosas que aparecen en un libro <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20730/20730_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Encuentra cosas incluso cuando estén oculto bajo dos o tres cubiertas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20731/20731_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20732/20732_lores.jpg']} /></>,
        <>Empieza a clasificar por formas y colores <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20733/20733_lores.jpg']} /></>,
        <>Completa las frases y las rimas de los cuentos que conoce  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/e_X8gip0ikQ']} /></>,
        <>Juega con su imaginación de manera sencilla <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20734/20734_lores.jpg']} /></>,
        <>Construye torres de 4 bloques o más <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20735/20735_lores.jpg']} /></>,
        <>Puede que use una mano más que la otra  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/RIUgcQSHAVc']} /></>,
        <>Sigue las instrucciones de dos pasos como "Recoge tus zapatos y ponlos en el armario"  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/tySEn371aZM']} /></>,
        <>Nombra las ilustraciones de los libros como un gato, un pájaro o un perro <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/HFg1eSu_KTk']} /></>
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Se para en las puntas de los pies <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20736/20736_lores.jpg']} /></>,
        <>Patea un balón  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20737/20737_lores.jpg']} /></>,
        <>Empieza a correr <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6vnTQD0qqGU']} /></>,
        <>Se trepa y baja de muebles sin ayuda <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20738/20738_lores.jpg']} /></>,
        <>Sube y baja las escaleras agarrándose <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20740/20740_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20739/20739_lores.jpg']} /></>,
        <>Tira la pelota por encima del hombro <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20741/20741_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20741/20741_lores.jpg']} /></>,
        <>Dibuja o copia líneas rectas y círculos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6XV8xXdy0lg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        'No usa frases de 2 palabras (por ejemplo, “toma leche”)',
        'No sabe cómo utilizar objetos de uso común, como un cepillo, un teléfono, un tenedor o una cuchara',
        'No copia acciones ni palabras',
        'No puede seguir instrucciones sencillas',
        'No camina con estabilidad',
        'Pierde habilidades que había logrado',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
