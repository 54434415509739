import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 36
export const pageTitle = 'Indicadores importantes: su bebé a los 3 años'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final de los 3 años completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: 'Lo que la mayoría de los bebés hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Copia a adultos y amigos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20743/20743_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20744/20744_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20745/20745_lores.jpg']} /></>,
        <>Demuestra afecto por sus amigos espontáneamente <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20910/20910_lores.jpg']} /></>,
        <>Espera su turno en los juegos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/c9KtszF2_oc']} /></>,
        <>Demuestra preocupación si ve llorar a un amigo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20747/20747_lores.jpg']} /></>,
        <>Entiende la idea de lo que “es mío”, “de él” o “de ella”  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Om-zZvqXPlY']} /></>,
        <>Expresa una gran variedad de emociones <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20746/20746_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20748/20748_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20749/20749_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20750/20750_lores.jpg']} /></>,
        <>Se separa de su mamá y su papá con facilidad <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20751/20751_lores.jpg']} /></>,
        <>Puede que se moleste cuando hay grandes cambios de rutina <ExternalLink icon={faCamera} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/images/mayGetUpset.jpg']} /></>,
        <>Se viste y desviste <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20752/20752_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20753/20753_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Sigue instrucciones de 2 o 3 pasos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/-d6rgPOGnSQ']} /></>,
        <>Sabe el nombre de la mayoría de las cosas conocidas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/LY8Ot4DJjpU']} /></>,
        <>Entiende palabras como “adentro”, “arriba” o “debajo” <ExternalLink icon={faCamera} resources={['https://www.youtube.com/embed/xIqVMimUWN0']} /></>,
        <>Puede decir su nombre, edad y sexo <ExternalLink icon={faCamera} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/images/says-first-name-age-and-sex-700px.jpg']} /></>,
        <>Llama a un amigo por su nombre  <ExternalLink icon={faCamera} resources={['https://www.youtube.com/embed/ogqu1lRL4j8']} /></>,
        <>Dice palabras como “yo”, “mi”, “nosotros”, “tú” y algunos plurales (autos, perros, gatos)  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/T4tybEWGaIg']} /></>,
        <>Puede conversar usando 2 o 3 oraciones  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/k0Hu0FOe5Pc']} /></>,
        <>Habla bien de manera que los desconocidos pueden entender la mayor parte de lo que dice <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/dlFBFObuVlM']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Puede operar juguetes con botones, palancas y piezas móviles <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20760/20760_lores.jpg']} /></>,
        <>Juega imaginativamente con muñecas, animales y personas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20754/20754_lores.jpg']} /></>,
        <>Arma rompecabezas de 3 o 4 piezas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20755/20755_lores.jpg']} /></>,
        <>Entiende lo que significa “dos”  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/BUDDlzpgYc0']} /></>,
        <>Copia un círculo con lápiz o crayón <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20756/20756_lores.jpg']} /></>,
        <>Pasa las hojas de los libros una a la vez <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20757/20757_lores.jpg']} /></>,
        <>Arma torres de más de 6 bloquecitos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20758/20758_lores.jpg']} /></>,
        <>Enrosca y desenrosca las tapas de jarras o abre la manija de la puerta <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20759/20759_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Trepa bien <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20911/20911_lores.jpg']} /></>,
        <>Corre fácilmente  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/wkOOxsIrwgM']} /></>,
        <>Puede pedalear un triciclo (bicicleta de 3 ruedas) <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20761/20761_lores.jpg']} /></>,
        <>Sube y baja escaleras, con un pie por escalón  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20763/20763_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        'Se cae mucho o tiene problemas para subir y bajar escaleras',
        'Se babea o no se le entiende cuando habla',
        'No puede operar juguetes sencillos (tableros de piezas para encajar, rompecabezas sencillos, girar una manija)',
        'No usa oraciones para hablar',
        'No entiende instrucciones sencillas',
        'No imita ni usa la imaginación en sus juegos',
        'No quiere jugar con otros niños ni con juguetes',
        'No mira a las personas a los ojos',
        'Pierde habilidades que había adquirido',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
