import React, { useContext } from 'react'
import SimplePage from '/src/components/SimplePage'
import * as en from '/src/locales/en/faqs'
import * as es from '/src/locales/es/faqs'
import { GlobalContext } from '/src/components/GlobalContext'

const Faqs = ({ }) => {
  const { i18n } = useContext(GlobalContext)
  const copy = i18n === 'en' ? en : es

  return (
    <SimplePage title={copy.pageTitle} copy={copy.pageCopy} />
  )
}

export default Faqs
