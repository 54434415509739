import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, isActive, isInactive } from './Modal'

export const ExternalLink = ({ icon, resources }) => {
  const [modalState, setModalState] = useState(isInactive)
  function toggleModal(newState) {
    setModalState(newState)
  }

  return (
    <>
    <FontAwesomeIcon
      icon={icon}
      onClick={() => toggleModal(isActive)}
    />
    <Modal
      icon={icon}
      resources={resources}
      state={modalState}
      toggleModal={() => toggleModal(isInactive)}
    />
    </>
  )
}

export default ExternalLink
