import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

const SocialMediaLinks = ({}) => (
  <>
    <div className='column is-1'>
      <a href='https://www.facebook.com/actearlydc/' target='_blank'>
        <FontAwesomeIcon icon={faFacebook} color='#4267B2' size='2x' />
      </a>
    </div>
    <div className='column is-1'>
      <a href='https://twitter.com/actearlydc?lang=en' target='_blank'>
        <FontAwesomeIcon icon={faTwitter} color='#1DA1F2' size='2x' />
      </a>
    </div>
    <div className='column is-1'>
      <a href='https://www.youtube.com/playlist?list=PLEhZQU_WEgEu_iLBwqugcpp6obfDbjqjF' target='_blank'>
        <FontAwesomeIcon icon={faYoutube} color='#FF0000' size='2x' />
      </a>
    </div>
  </>
)

export default SocialMediaLinks
