import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 2
export const pageTitle = 'Indicadores importantes: su bebé a los 2 meses'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final de los 2 meses completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: 'Lo que la mayoría de los bebés hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Empieza a sonreirle a la gente <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20651/20651_lores.jpg']} /></>,
        <>Puede calmarse sin ayuda por breves momentos (se pone los dedos en la boca y se chupa la mano)  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20652/20652_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20653/20653_lores.jpg']} /></>,
        <>Trata de mirar a sus padres <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20654/20654_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Hace sonidos como de arrullo o gorjeos <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/ktGf_CSVCVk']} /></>,
        <>Mueve la cabeza hacia los sonidos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/7LNUNCfvwKk']} /></>
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Se interesa en las caras <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20655/20655_lores.jpg']} /></>,
        <>Comienza a seguir las cosas con los ojos y reconoce a las personas a la distancia <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20656/20656_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20657/20657_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20658/20658_lores.jpg']} /></>,
        <>Comienza a demostrar aburrimiento si no hay un cambio de actividad (llora, se inquieta)  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/HJS68Ls9rB8']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Puede mantener la cabeza alzada y trata de levantarse cuando está boca abajo  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20659/20659_lores.jpg']} /></>,
        <>Mueve las piernas y los brazos con mayor soltura  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/uFLYxxEGPgs']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        'No responde ante ruidos fuertes',
        'No sigue con la vista a las cosas que se mueven',
        'No le sonríe a las personas',
        'No se lleva las manos a la boca',
        'No puede sostener la cabeza en alto cuando empuja el cuerpo hacia arriba estando boca abajo',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
