import React from "react";
import blockParty from "../../images/marshall_block_party.png";
import dailyCalmness from "../../images/every_calm_tips_for_parents.png";
import holidayCalm from "../../images/holiday_calm.jpeg";
import toolkitPdf from "../../images/toolkit.pdf";
import toolkitDetailedPdf from "../../images/toolkit-detailed.pdf";
import toolkitPng from "../../images/toolkit.png";

export const pageCopy = [
  {
    title: "Mr. Marshall’s Block Party",
    body: [
      "The annual block party in Mr. Marshall's Neighborhood is underway! Everyone in the neighborhood is pitching in, even the children Mr. Marshall has watched grow over the years. Follow Mr. Marshall and his community as they prepare for the biggest celebration of the year!",
      "“Mr. Marshall's Block Party” was written by the CDC’s Act Early Ambassador to DC, Yetta Myrick with Morgan Cruise and illustrated by Jennifer Price Davis to support Black and African American families in monitoring their child's development.",
      <>
        Watch the{" "}
        <a href="https://www.youtube.com/watch?v=f2zAhEvomiA&amp;feature=youtu.be">
          authors' interview
        </a>{" "}
        to learn about how and why this children's book was created!
      </>,
      <>
        Download the{" "}
        <a href="https://drive.google.com/file/d/1QZAI16F8CMGXgPs-aikmIV8CZJ-rbdNE/view">
          Mr. Marshall's Block Party E-Book!
        </a>
      </>,
      <>
        <div style={{ maxWidth: "300px" }}>
          <figure className="image">
            <img src={blockParty} alt="DCAP toolkit image" />
          </figure>
        </div>
      </>,
    ],
  },
  {
    title: "Act Early DC Newsletter",
    body: [
      "The Act Early DC Newsletter, our electronic newsletter, offer topics of interest for families/caregiver of children ages birth-5 years to support them in navigating the four steps of early identification: 1) parent-engaged developmental monitoring, 2) developmental and autism screening, 3) referral and 4) receipt of early intervention services. The newsletter provides tips that might be helpful in day-to-day life, and announcement of events and opportunities through the Act Early DC Program and local community.",
      "Below please find our most recent newsletter:",
      <>
        <a href="https://conta.cc/3Ee7loF">November 2022</a>
        <br />
        <a href="https://conta.cc/3poGWO4">August 2022</a>
        <br />
        <a href="https://conta.cc/3L6Gj4c">May 2022</a>
        <br />
        <a href="https://conta.cc/3HYp4Ba">February 2022</a>
        <br />
        <a href="https://conta.cc/3Dtn8hT)">November 2021</a>
      </>,
    ],
  },
  {
    title: "Tips to Help Parents Keep Calm Every Day Toolkit",
    body: [
      <>
        This toolkit was developed to support families during the COVID-19
        Pandemic and beyond. This toolkit includes not only tips for parents,
        but a variety of resources including a series of videos curated and led
        by Adriana Adelé, a registered yoga teacher, to help support you through
        movement, breath, and/or mindful awareness; and a group coaching session
        led by Master Certified Life Coach, Natalie Miller, who provides tools
        for parents to support themselves as they support their children.
        <br />
        <a href="https://drive.google.com/file/d/1NDflt9y7HA8nrHlCILWpO2_6P78DOBZl/view?usp=sharing">
          Download the toolkit here.
        </a>
      </>,
      <section className="section container has-text-centered">
        <div className="is-flex is-flex-direction-row is-justify-content-space-evenly container">
          <div style={{ maxWidth: "250px" }}>
            <figure className="image">
              <img src={dailyCalmness} alt="DCAP toolkit image" />
            </figure>
          </div>
        </div>
      </section>,
    ],
  },
  {
    title: "Tips to Help Parents Keep Calm During the Holidays",
    body: [
      <>
        This toolkit was developed to support families during the holiday season
        and beyond. It includes tips and resources to help families keep calm
        and take care of themselves during the holidays, especially when this
        time of year is often focused on their children. <br />
        <a href="https://www.dropbox.com/sh/ml3ls061egf6yzu/AAC3WAhviFe5U06MMYDHfKGMa?dl=0)">
          Download the toolkit here
        </a>
      </>,
      <section className="section container has-text-centered">
        <div className="is-flex is-flex-direction-row is-justify-content-space-evenly container">
          <div style={{ maxWidth: "250px" }}>
            <figure className="image">
              <img src={holidayCalm} alt="DCAP toolkit image" />
            </figure>
          </div>
        </div>
      </section>,
    ],
  },
  {
    title: "Act Early DC Toolkit",
    body: [
      "The Act Early DC Parent Toolkit was designed to introduce families/caregivers to the five Strengthening Families Protective Factors and to provide tips on how families can support their young children during the COVID-19 Pandemic and beyond.",
      "Below please find the plain language and detailed toolkits.",
      <section className="section container has-text-centered">
        <div className="is-flex is-flex-direction-row is-justify-content-space-evenly container">
          <div style={{ maxWidth: "250px" }}>
            <figure className="image">
              <img src={toolkitPng} alt="DCAP toolkit image" />
            </figure>
          </div>
        </div>
        <a href={toolkitPdf}>Download Our Toolkit Here</a>
        <br />
        <a href={toolkitDetailedPdf}>Download Our Detailed Toolkit Here</a>
      </section>,
    ],
  },
  {
    title: "Links to Helpful Websites for Parents",
    body: [
      <a href="https://www.cdc.gov/nutrition/infantandtoddlernutrition/index.html">
        Infant and Toddler Nutrition
      </a>,
      "The Centers for Disease Control and Prevention's Infant and Toddler Nutrition website brings together existing information and practical strategies on feeding healthy foods and drinks to infants and toddlers, from birth to 24 months of age. Parents and caregivers can explore these pages to find nutrition information to help give their children a healthy start in life.",
      <a href="https://healthychildren.org/English/Pages/default.aspx">
        HealthyChildren.org
      </a>,
      "HealthyChildren.org is a pediatrician backed parenting website that provides information and recommendations related to child health, guidance on parenting issues, and more from the American Academy of Pediatrics.",
      <a href="https://jitp.info/">Just in Time Parenting (JITP)</a>,
      "JITP features many resources that provide parents with the information they need to support and guide the growth of their child.",
      <a href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/index.html">
        Positive Parenting Tips
      </a>,
      "As your child grows and develops, there are many things you can do to help your child. These links will help you learn more about positive parenting, safety, and health at each stage of your child’s life. Find tips for positive parenting and child safety according to the age groups below.",
      [
        <a href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/infants.html">
          Infants 0-1 years old
        </a>,
        <a href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/toddlers.html">
          Toddlers 1-2 years old
        </a>,
        <a href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/toddlers2.html">
          Toddlers 2-3 years old
        </a>,
        <a href="https://www.cdc.gov/ncbddd/childdevelopment/positiveparenting/preschoolers.html">
          Preschoolers 3-5 years old
        </a>,
      ],
      <a href="https://www.healthychildren.org/English/MotorDelay/Pages/default.aspx#/">
        Physical Developmental Delays: What to Look For
      </a>,
      "Physical Developmental Delays is a tool for parents concerned about the physical development of their child. The tool reinforces that parents know their child best and should trust their instincts.",
    ],
  },
  {
    title: "Resources to Help Promote Your Child’s Development",
    body: [
      <a href="http://talkingisteaching.org/resources/developmental-milestones-handout">
        Talking is Teaching: Talk, Read, Sing
      </a>,
      "“Talking is Teaching: Talk, Read, Sing” is a public awareness and action campaign that helps parents recognize their power to boost their children’s early brain and vocabulary development through simple, everyday actions.",
      <a href="https://www.vroom.org/">Vroom</a>,
      "Vroom has brain-building tips to support your child’s development. Use these tips with your child during their first 5 years to help give them a strong base for lifelong learning.",
      [
        <>
          Download the{" "}
          <a href="https://www.cdc.gov/ncbddd/actearly/pdf/vroom/Vroom-Poster_14x8.5_FNL-508.pdf">
            Milestones Matter with CDC and Vroom poster
          </a>{" "}
          as a helpful resource for tracking your child’s development and
          learning about brain building tips.
        </>,
      ],
      <a href="https://www.zerotothree.org/early-development">Zero to Three</a>,
      "Zero to Three has science-based information and tools designed to help parents and caregivers nurture their young children’s development.",
    ],
  },
];
