import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 6
export const pageTitle = 'Important Milestones: Your baby by Six Months'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 6 months by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Knows familiar faces and begins to know if someone is a stranger <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20676/20676_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20675/20675_lores.jpg']} /></>,
        <>Likes to play with others, especially parents <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20677/20677_lores.jpg']} /></>,
        <>Responds to other people’s emotions and often seems happy <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/_ewfCVshil0']} /></>,
        <>Likes to look at self in a mirror <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20678/20678_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Responds to sounds by making sounds <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/TkFgYc303f0']} /></>,
        <>Strings vowels together when babbling (“ah,” “eh,” “oh”) and likes taking turns with parent while making sounds <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/-RCSbpez9EU']} /></>,
        <>Responds to own name <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/H5yukc5becc']} /></>,
        <>Makes sounds to show joy and displeasure <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/XvDXOc4YH8c']} /></>,
        <>Begins to say consonant sounds (jabbering with “m,” “b”) <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/hmQUUDYUeFE']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Looks around at things nearby <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/1MFgx-pr8hs']} /></>,
        <>Brings things to mouth <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20679/20679_lores.jpg']} /></>,
        <>Shows curiosity about things and tries to get things that are out of reach <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20680/20680_lores.jpg']} /></>,
        <>Begins to pass things from one hand to the other <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20681/20681_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Rolls over in both directions (front to back, back to front) <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/KybaUJNhmpo']} /></>,
        <>Begins to sit without support <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20682/20682_lores.jpg']} /></>,
        <>When standing, supports weight on legs and might bounce <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Y3EySKt7BmU']} /></>,
        <>Rocks back and forth, sometimes crawling backward before moving forward <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/gnwtliGFY5s']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        'Doesn’t try to get things that are in reach',
        'Shows no affection for caregivers',
        'Doesn’t respond to sounds around him',
        'Has difficulty getting things to mouth',
        'Doesn’t make vowel sounds (“ah”, “eh”, “oh”)',
        'Doesn’t roll over in either direction',
        'Doesn’t laugh or make squealing sounds',
        'Seems very stiff, with tight muscles',
        'Seems very floppy, like a rag doll',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
