import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 60
export const pageTitle = 'Important Milestones: Your child by 5 Years'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 5 years by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Wants to please friends <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20925/20925_lores.jpg']} /></>,
        <>Wants to be like friends <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20775/20775_lores.jpg']} /></>,
        <>More likely to agree with rules <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/VsgmuC0djAU']} /></>,
        <>Likes to sing, dance, and act <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/xlqGanSXN8g']} /></>,
        <>Is aware of gender <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/l_iZ1lzA6sM']} /></>,
        <>Can tell what’s real and what’s make-believe <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/2VwWLNRSKLU']} /></>,
        <>Shows more independence (for example, may visit a next-door neighbor by himself [adult supervision is still needed]) <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20776/20776_lores.jpg']} /></>,
        <>Is sometimes demanding and sometimes very cooperative <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20774/20774_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20777/20777_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Speaks very clearly <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Q2bDUO0SxBg']} /></>,
        <>Tells a simple story using full sentences <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/tUFRtkgp5fs']} /></>,
        <>Uses future tense; for example, “Grandma will be here.” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/VcjjJVFdN8Q']} /></>,
        <>Says name and address</>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Counts 10 or more things <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/MUCzu4q70mU']} /></>,
        <>Can draw a person with at least 6 body parts <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20779/20779_lores.jpg']} /></>,
        <>Can print some letters or numbers <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/TtLlGLinONA']} /></>,
        <>Copies a triangle and other geometric shapes <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20778/20778_lores.jpg']} /></>,
        <>Knows about things used every day, like money and food <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/1Bv0XT3Hljs']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Stands on one foot for 10 seconds or longer <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Ijp0BvoHTzA']} /></>,
        <>Hops; may be able to skip <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/-Pp08tBs0wU']} /></>,
        <>Can do a somersault <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20780/20780_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20781/20781_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20782/20782_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20783/20783_lores.jpg']} /></>,
        <>Uses a fork and spoon and sometimes a table knife <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20784/20784_lores.jpg']} /></>,
        <>Can use the toilet on her own</>,
        <>Swings and climbs <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20785/20785_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20786/20786_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        "Doesn’t show a wide range of emotions",
        "Shows extreme behavior (unusually fearful, aggressive, shy or sad)",
        "Unusually withdrawn and not active",
        "Is easily distracted, has trouble focusing on one activity for more than 5 minutes",
        "Doesn’t respond to people, or responds only superficially",
        "Can’t tell what’s real and what’s make-believe",
        "Doesn’t play a variety of games and activities",
        "Can’t give first and last name",
        "Doesn’t use plurals or past tense properly",
        "Doesn’t talk about daily activities or experiences",
        "Doesn’t draw pictures",
        "Can’t brush teeth, wash and dry hands, or get undressed without help",
        "Loses skills he once had",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
