import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 60
export const pageTitle = 'Indicadores importantes: su bebé a los 5 años'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final de los 5 años completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: 'Lo que la mayoría de los niños hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Quiere complacer a los amigos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20925/20925_lores.jpg']} /></>,
        <>Quiere parecerse a los amigos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20775/20775_lores.jpg']} /></>,
        <>Es posible que haga más caso a las reglas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/VsgmuC0djAU']} /></>,
        <>Le gusta cantar, bailar y actuar  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/xlqGanSXN8g']} /></>,
        <>Reconoce a qué sexo pertenecen las personas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/l_iZ1lzA6sM']} /></>,
        <>Puede distinguir la fantasía de la realidad  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/2VwWLNRSKLU']} /></>,
        <>A veces es muy exigente y a veces muy cooperador <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20776/20776_lores.jpg']} /></>,
        <>Muestra más independencia (por ejemplo, puede ir solo a visitar a los vecinos de al lado [para esto todavía necesita la supervisión de un adulto <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20774/20774_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20777/20777_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Habla con mucha claridad  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Q2bDUO0SxBghttps://www.youtube.com/embed/Q2bDUO0SxBg']} /></>,
        <>Puede contar una historia sencilla usando oraciones completas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/tUFRtkgp5fs']} /></>,
        <>Puede usar el tiempo futuro  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/VcjjJVFdN8Q']} /></>,
        <>Dice su nombre y dirección</>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Cuenta 10 o más cosas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/MUCzu4q70mU']} /></>,
        <>Puede dibujar una persona con al menos 6 partes del cuerpo  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20779/20779_lores.jpg']} /></>,
        <>Puede escribir algunas letras o números  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/TtLlGLinONA']} /></>,
        <>Puede copiar triángulos y otras figuras geométricas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20778/20778_lores.jpg']} /></>,
        <>Conoce las cosas de uso diario como el dinero y la comida  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/1Bv0XT3Hljs']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Se para en un pie por 10 segundos o más  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Ijp0BvoHTzA']} /></>,
        <>Brinca y puede ser capaz de avanzar dando saltitos cortos alternando entre un pie y el otro  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/-Pp08tBs0wU']} /></>,
        <>Puede dar volteretas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20780/20780_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20781/20781_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20782/20782_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20783/20783_lores.jpg']} /></>,
        <>Usa tenedor y cuchara y, a veces, cuchillogeométricas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20784/20784_lores.jpg']} /></>,
        <>Se columpia y trepa</>,
        <>Puede ir al baño solo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20785/20785_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20786/20786_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        'No expresa una gran variedad de emociones',
        'Tiene comportamientos extremos (demasiado miedo, agresión, timidez o tristeza)',
        'Es demasiado retraído y pasivo',
        'Se distrae con facilidad, tiene problemas para concentrarse en una actividad por más de 5 minutos',
        'No le responde a las personas o lo hace solo superficialmente',
        'No puede distinguir la fantasía de la realidad',
        'No juega a una variedad de juegos y actividades',
        'No puede decir su nombre y apellido',
        'No usa correctamente los plurales o el tiempo pasado',
        'No habla de sus actividades o experiencias diarias',
        'No dibuja',
        'No puede cepillarse los dientes, lavarse y secarse las manos o desvestirse sin ayuda',
        'Pierde habilidades que había adquirido',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
