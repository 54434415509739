import React, { useContext } from 'react'
import dcLogo from '/src/images/act_early_dc.svg'
import { Card } from '/src/components/Card'
import * as en from '/src/locales/en/index'
import * as es from '/src/locales/es/index'
import { GlobalContext } from '/src/components/GlobalContext'

export const Index = ({ }) => {
  const { i18n } = useContext(GlobalContext)
  const copy = i18n === 'en' ? en : es

  return(
    <section className='section'>
      <div style={{ maxWidth: '500px', margin: 'auto' }}>
        <figure className="image is-3by-1">
          <img src={dcLogo} alt="Act Early DC Logo" alt="Learn the Signs Act Early DC" />
        </figure>
      </div>
      <div className='level-item content'>
        <h1 className="title is-family-primary has-text-primary is-size-3-desktop is-size-2-tablet is-size-4-mobile">
          {copy.pageTitle}
        </h1>
      </div>
      <div className='content is-family-primary'>
        <section className='section'>
          {copy.paragraphs.map(c => <div className='content'>{c}</div>)}
        </section>
      </div>
      <div className='is-flex is-justify-content-space-around is-flex-wrap-wrap'>
        {copy.cards.map(c =>
          <Card
            path={c.path}
            url={c.url}
            imageSrc={c.imageSrc} 
            header={c.header}
            content={c.content}
          />
        )}
      </div>
    </section>
  )
}

export default Index
