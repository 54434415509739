import React from 'react'

export const pageTitle = 'Referral and Evaluation'
export const pageCopy = [
  {
    body: [
      "If you are concerned about your child’s development, or if you or your child’s doctor think your child may be delayed, ask for a referral to a specialist who can do a more in-depth evaluation of your child.",
      "You child’s doctor may refer you to:",
      [
        "Developmental pediatricians. These doctors have special training in child development and children with special needs.",
        "Child neurologists. These doctors work on the brain, spine, and nerves.",
        "Child psychologists or psychiatrists. These doctors know about the human mind.",
      ],
      "Additionally, you will want to ask your child’s doctor for a referral for an early intervention evaluation.",
      <>
        You can also call Strong Start Child Find Hotline at (202) 727-3665 or fill out the <a href='https://eip.osse.dc.gov/ReferralForm/Create'>referral form</a> for children 0-2 years, 8 months or submit a <a href='https://www.earlystagesdc.org/form/referral-form'>referral form</a> to Early Stages for children 2 years, 8 months to 5 years, 10 months.  Please note that the special education evaluation process will only begin after a parent/guardian has provided written consent.
      </>
    ]
  },
  {
    title: 'What is a Developmental Evaluation?',
    body: [
      'A developmental evaluation takes a more in-depth look at a child’s development and can identify and diagnose developmental delays and conditions.'
    ]
  },
  {
    title: 'Who does a Developmental Evaluation?',
    body: [
      'A developmental evaluation is usually done by a trained specialist, such as a developmental pediatrician, child psychologist, speech-language pathologist, occupational therapist, or other specialist.'
    ]
  },
  {
    title: 'What happens during a Developmental Evaluation?',
    body: [
      "The specialist may observe your child, administer a structured test, interview you, and/or ask you to fill out questionnaires." ,
      "The results of this formal evaluation determine whether your child needs specific treatment, early intervention services, or both.",
      <>
      You should keep records of conversations and communications with providers and agencies.  The Center for Parent Information and Resources created a <a href='https://www.parentcenterhub.org/wp-content/uploads/repo_items/recordkeeping.pdf'>Sample Record Keeping Worksheet.</a>
      </>
    ]
  },
  {
    title: 'A Note About Privacy:',
    body: [
      "As you navigate these systems, you may have concerns regard your child’s privacy.  The Family Educational Rights and Privacy Act (FERPA) and Health Insurance Portability and Accountability Act of 1996 (HIPAA) were established to protect your child’s privacy.  Learn more below:"
    ]
  },
  {
    title: 'Family Educational Rights and Privacy Act (FERPA)',
    body: [
      <>
        The Family Educational Rights and Privacy Act (FERPA) provides certain rights for parents regarding their children’s education records.  FERPA is a federal law that is administered by the Student Privacy Policy Office (SPPO) in the U.S. Department of Education (Department). FERPA protects “education records,” which are generally defined as records that are directly related to a student and maintained by an educational agency or institution or by a party acting for the agency or institution. <a href='https://studentprivacy.ed.gov/resources/ferpa-general-guidance-parents'>A Parent’s Guide to FERPA</a> is intended to provide parents with general information about FERPA.
      </>
    ]
  },
  {
    title: 'Health Insurance Portability and Accountability Act of 1996 (HIPAA)',
    body: [
      <>
        The Health Insurance Portability and Accountability Act of 1996 (HIPAA), gives you rights over your health information, including the right to get a copy of your information, make sure it is correct, and know who has seen it.  You can learn more by visiting the <a href='https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html'>U.S. Department of Health and Human Services HIPPA for Individuals webpage</a>.
      </>
    ]
  },
]
