import oneMonth from '/src/images/1_month.jpeg';
import twoMonth from '/src/images/2_months.jpeg';
import threeMonth from '/src/images/3_months.jpeg';
import fourMonth from '/src/images/4_months.jpeg';
import sixMonth from '/src/images/6_months.jpeg';
import nineMonth from '/src/images/9_months.jpeg';
import twelveMonth from '/src/images/12_months.jpeg';
import eighteenMonth from '/src/images/18_months.jpeg';
import twentyFourMonth from '/src/images/24_months.jpeg';
import thirtySixMonth from '/src/images/36_months.jpeg';
import fourtyEightMonth from '/src/images/48_months.jpeg';
import sixtyMonth from '/src/images/60_months.jpeg';

export const pageTitle = "Developmental Milestones"
export const pageBody = 'Skills such as taking a first step, smiling for the first time, and waving “bye bye” are called developmental milestones. Children reach milestones in how they play, learn, speak, act, and move (crawling, walking, etc.).'
export const BabyImageLink = [
  {
    linkTo: '/milestones/1_month',
    imageSrc: oneMonth,
    header: '1 Month',
  },
  {
    linkTo: '/milestones/2_months',
    imageSrc: twoMonth,
    header: '2 Months',
  },
  {
    linkTo: '/milestones/3_months',
    imageSrc: threeMonth,
    header: '3 Months',
  },
  {
    linkTo: '/milestones/4_months',
    imageSrc: fourMonth,
    header: '4 Months',
  },
  {
    linkTo: '/milestones/6_months',
    imageSrc: sixMonth,
    header: '6 Months',
  },
  {
    linkTo: '/milestones/9_months',
    imageSrc: nineMonth,
    header: '9 Months',
  },
  {
    linkTo: '/milestones/12_months',
    imageSrc: twelveMonth,
    header: '1 Year',
  },
  {
    linkTo: '/milestones/18_months',
    imageSrc: eighteenMonth,
    header: '18 Months',
  },
  {
    linkTo: '/milestones/2_years',
    imageSrc: twentyFourMonth,
    header: '2 Years',
  },
  {
    linkTo: '/milestones/3_years',
    imageSrc: thirtySixMonth,
    header: '3 Years',
  },
  {
    linkTo: '/milestones/4_years',
    imageSrc: fourtyEightMonth,
    header: '4 Years',
  },
  {
    linkTo: '/milestones/5_years',
    imageSrc: sixtyMonth,
    header: '5 Years',
  },
]
