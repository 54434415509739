import React, { useContext } from 'react'
import SimplePage from '../components/SimplePage'
import * as en from '/src/locales/en/evaluations'
import * as es from '/src/locales/es/evaluations'
import { GlobalContext } from '/src/components/GlobalContext'

const Evaluations = ({ }) => {
  const { i18n } = useContext(GlobalContext)
  const copy = i18n === 'en' ? en : es

  return (
    <SimplePage
      title={copy.pageTitle}
      copy={copy.pageCopy}
    />
  )
}

export default Evaluations
