import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 6
export const pageTitle = 'Indicadores importantes: su bebé a los 6 meses'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final de los 6 meses completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: 'Lo que la mayoría de los bebés hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Reconoce las caras familiares y comienza darse si alguien es un desconocido <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20676/20676_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20675/20675_lores.jpg']} /></>,
        <>Le gusta jugar con los demás, especialmente con sus padres <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20677/20677_lores.jpg']} /></>,
        <>Responde a las emociones de otras personas y parece feliz  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/_ewfCVshil0']} /></>,
        <>Le gusta mirarse en el espejo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20678/20678_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Responde a los sonidos produciendo sonidos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/TkFgYc303f0']} /></>,
        <>Une varias vocales cuando balbucea (“a”, “e”, “o”) y le gusta hacer sonidos por turno con los padres  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/-RCSbpez9EU']} /></>,
        <>Reacciona cuando se menciona su nombre  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/H5yukc5becc']} /></>,
        <>Emite sonidos para demostrar alegría o descontento <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/XvDXOc4YH8c']} /></>,
        <>Comienza a emitir sonidos de consonantes (parlotea usando la “m” o la “b”)  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/hmQUUDYUeFE']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Observa a su alrededor las cosas que están cerca <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/1MFgx-pr8hs']} /></>,
        <>Se lleva las cosas a la boca <ExternalLink icon={faVideo} resources={['http://phil.cdc.gov/PHIL_Images/20679/20679_lores.jpg']} /></>,
        <>Comienza a pasar cosas de una mano a la otra <ExternalLink icon={faVideo} resources={['http://phil.cdc.gov/PHIL_Images/20680/20680_lores.jpg']} /></>,
        <>Demuestra curiosidad sobre las cosas y trata de agarrar las cosas que están fuera de su alcance <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20681/20681_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Se da vuelta para ambos lados (se pone boca arriba y boca abajo)  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/KybaUJNhmpo']} /></>,
        <>Comienza a sentarse sin apoyo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20682/20682_lores.jpg']} /></>,
        <>Cuando se para, se apoya en sus piernas y hasta puede ser que salte <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Y3EySKt7BmU']} /></>,
        <>Se mece hacia adelante y hacia atrás, a veces gatea primero hacia atrás y luego hacia adelante  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/gnwtliGFY5s']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        "No trata de agarrar cosas que están a su alcance",
        "No demuestra afecto por quienes le cuidan",
        "No reacciona ante sonidos",
        "Tiene dificultad para llevarse cosas a la boca",
        "No emite sonidos de vocales (“a”, “e”, “o”)",
        "No rueda en ninguna dirección para darse vuelta",
        "No se ríe ni hace sonidos de placer",
        "Se ve rígido y con los músculos tensos",
        "Se ve sin fuerza como un muñeco de trapo",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
