import React from 'react'
import GlobalContextProvider from "./GlobalContext.js"
import { Helmet } from 'react-helmet'
import Navbar from '../components/Navbar'
import './all.sass'
import Footer from './Footer'
const URI_TITLE_MAP = {
  '/': 'Learn The Signs Act Early DC',
  '/milestones': 'LTSAE Milestones',
  '/monitoring': 'LTSAE Monitoring',
  '/screening': 'LTSAE Screening',
  '/evaluations': 'LTSAE evaluations',
  '/early_intervention_services': 'LTSAE Early Intervention Services',
  '/autism_resources': 'LTSAE Autism Resources',
  '/faqs': 'LTSAE faq',
  '/parent_resources': 'LTSAE Parent Resources',
  '/about_us': 'LTSAE About Us',
  '/milestones/1_month': 'LTSAE 1 Month Milestones',
  '/milestones/2_months': 'LTSAE 2 Month Milestones',
  '/milestones/3_months': 'LTSAE 3 Month Milestones',
  '/milestones/4_months': 'LTSAE 4 Month Milestones',
  '/milestones/6_months': 'LTSAE 6 Month Milestones',
  '/milestones/9_months': 'LTSAE 9 Month Milestones',
  '/milestones/12_months': 'LTSAE 12 Month Milestones',
  '/milestones/18_months': 'LTSAE 18 Month Milestones',
  '/milestones/2_years': 'LTSAE 2 Year Milestones',
  '/milestones/3_years': 'LTSAE 3 Year Milestones',
  '/milestones/4_years': 'LTSAE 4 Year Milestones',
  '/milestones/5_years': 'LTSAE 5 Year Milestones',
}

const TemplateWrapper = ({ children, uri }) => {
  return (
    <GlobalContextProvider>
        <Helmet>
          <html lang="en" />
          <title>{URI_TITLE_MAP[uri]}</title>
          <meta name="description" content='Learn the Signs Act Early is a CDC program. Help parents and caregivers in the Washington DC area to help recognize and get help for children with developmental disabilities or delays, not limited to autism.' />
          <meta name="theme-color" content="#fff" />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content='Learn The Signs Act Early DC' />
          <meta property="og:url" content="/" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap" rel="stylesheet" />
          <script type="application/ld+json">
          {
            `{
              "@context": "https://schema.org",
              "@type": "MedicalCondition",
              "alternateName": "Autism",
              "associatedAnatomy": {
                "@type": "BrainStructure",
                "name": "Autism"
              },
              "code": {
                "@type": "MedicalCode",
                "code": "F84.0",
                "codingSystem": "ICD-10"
              },
              "name": "Autism",
              "riskFactor": [
                {
                  "@type": "MedicalRiskFactor",
                  "name": "Age"
                },
                {
                  "@type": "MedicalRiskFactor",
                  "name": "Gender"
                },
                {
                  "@type": "MedicalRiskFactor",
                  "name": "Systolic blood pressure"
                },
                {
                  "@type": "MedicalRiskFactor",
                  "name": "Smoking"
                },
                {
                  "@type": "MedicalRiskFactor",
                  "name": "Total cholesterol and/or cholesterol:HDL ratio"
                },
                {
                  "@type": "MedicalRiskFactor",
                  "name": "Coronary artery disease"
                }
              ],
              "signOrSymptom": [
                {
                  "@type": "MedicalSymptom",
                  "name": "chest discomfort"
                },
                {
                  "@type": "MedicalSymptom",
                  "name": "feeling of tightness, heaviness, or pain in the chest"
                }
              ]
            }`
          }
          </script>
        </Helmet>
        <Navbar />
        <div className='columns'>
          <div className='column is-three-fifths is-offset-one-fifth'>
            {children}
          </div>
        </div>
        <Footer />
    </GlobalContextProvider>
  )
}

export default TemplateWrapper
