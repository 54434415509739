import React from 'react'
import yetta from '../../images/yetta.jpg'
import alison from '../../images/alison.jpg'
import SocialMediaLinks from '/src/components/SocialMediaLinks'

export const pageTitle = "About Us"
export const pageCopy = [
  {
    title: "Find Us On Social Media",
    body: [
      <>
      <div className='columns is-mobile'>
        <SocialMediaLinks />
      </div>
      </>
    ]
  },
  {
    title: "Meet DC's Act Early Ambassador",
    body: [
      <>
        <figure className="image" style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}>
          <img src={yetta} />
        </figure>
      </>,
      <>
        <b>Yetta Myrick</b> is the mother of a teenage son diagnosed with an autism spectrum disorder and Intellectual Disability.  She is the Founder and President of DC Autism Parents (DCAP), a 501(c)(3) non-profit organization in the District of Columbia, where she oversees the daily operations and has created programs for children and youth diagnosed with autism and their families.  Ms. Myrick has served as the CDC’s Act Early Ambassador to the District of Columbia since 2016 and is currently leading the DC Act Early COVID-19 Response Team Project.  She leads the DC Autism Collaborative’s Developmental Monitoring, Screening, and Evaluation Subgroup and serves as the Parent Educator/Advocate on the ECHO Autism HUB Team at Children’s National Hospital.  Ms. Myrick co-leads the Family Voices United to End Racism Against CYSHCN and Families Project.  She holds a Bachelor of Arts Degree in Communication Studies from The Catholic University of America.  Ms. Myrick was the Community Outreach Coordinator at the Center for Autism Spectrum Disorders (CASD) at Children’s National from 2016-2020 and a Studio Technician at C-SPAN from 2005-2016.  In 2019, she was appointed by DC Mayor Muriel Bowser to serve as a member of the DC Developmental Disabilities Council. Additionally, Ms. Myrick is a member of Interagency Autism Coordinating Committee and the Got Transition® National Family Health Care Transition Advisory Group.  She deeply believes that parental involvement is key to obtaining quality services for all children, and that an informed parent is an engaged parent.
      </>
    ]
  },
  {
    title: 'Yetta Myrick currently leads the DC Act Early COVID-19 Response Team',
    body: [
      <>
      Contact Yetta:&nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href='mailto:ymyrick@dcautismparents.org'
      >
        ymyrick@dcautismparents.org
      </a>
      </>
    ]
  },
  {
    title: 'State Partner',
    body: [
      <>
        <figure className="image" style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}>
          <img src={alison} />
        </figure>
      </>,
      'Alison Whyte, DC Developmental Disabilities Council',
      <>As a sibling of a person with a developmental disability, <b>Alison Whyte</b> has been working toward full inclusion of people with disabilities in work, school, and community life for as long as she can remember. Currently, Alison serves people with disabilities and their families in the District of Columbia as the Executive Director of the DC Developmental Disabilities Council. In this role, Alison leads grassroots efforts to build an inclusive DC community through policy advocacy and program development that promotes self-determination, integration, and social justice. Alison is also a certified trainer in person centered thinking through The Learning Community for Person Centered Practices (TLCPCP) and a member of the Supporting Families Community of Practice. Alison has prior experiences in research and statistical analysis, capacity building, policy advocacy, leadership development, and coalition building with organizations such as the DC Department on Disability Services, The Arc of DC, Best Buddies, the United Nations, the World Bank and the Montana Youth Leadership Forum. Additionally, Alison serves on the Board of Directors for Art Enables, an art studio in DC, and as the Secretary of the Board of Directors of TLCPCP.</>,
      'Alison has a Master’s of Social Work and Public Policy from the University of Michigan, where she focused on community organizing and policy advocacy as strategies for social change. Alison loves animals, being outdoors as much as possible, biking around DC, and practicing her Spanish and American Sign Language skills.'
    ]
  },
  {
    title: 'Alison Whyte currently co-leads the DC Act Early COVID-19 Response Team',
    body: [
      <>
      Contact Alison:&nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href='mailto:alison.whyte@dc.gov'
      >
        alison.whyte@dc.gov
      </a>
    </>
    ]
  },
  {
    title: 'DC Act Early COVID 19 Response Team',
    body: [
      'The DC Act Early COVID - 19 Response Team consists of stakeholders from the following organizations and government agencies:',
      [
        <>
        <a href='https://www.aje-dc.org/'>Advocates for Justice and Education, Inc.</a>
        </>,
        <>
        <a href='https://childrensnational.org/advocacy-and-outreach/child-health-advocacy-institute'>Children’s National Hospital-Child Health Advocacy Institute</a>
        </>,
        <>
        <a href='https://cfsa.dc.gov/'>Child and Family Services Agency</a>
        </>,
        <>
        <a href='https://ddc.dc.gov/'>DC Developmental Disabilities Council</a>
        </>,
        <>
        <a href='https://dchealth.dc.gov/page/title-v#:~:text=The%20DC%20Title%20V%20program,with%20special%20health%20care%20needs.&text=Every%20healthcare%20provider%20has%20the,needs%20of%20women%20and%20infants.'>DC Title V Program</a>
        </>,
        <>
        <a href='https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip'>DC Strong Start Early Intervention Program</a>
        </>,
        <>
        <a href='https://dchealth.dc.gov/'>DC Health</a>
        </>,
        <>
        <a href='http://aapdc.org/'>DC Chapter American Academy of Pediatrics</a>
        </>,
        <>
        <a href='https://www.dcheadstart.org/'>DC Head Start Association</a>
        </>,
        <>
        <a href='https://dchealth.dc.gov/service/special-supplemental-nutrition-program-women-infants-and-children-wic'>DC Women, Infant, and Children (WIC) Program</a>
        </>,
        <>
        <a href='https://www.earlystagesdc.org/'>Early Stages</a>
        </>,
        <>
        <a href='https://helpmegrow.dc.gov/'>Help Me Grow DC</a>
        </>,
        <>
        <a href='https://thrivebyfive.dc.gov/'>Thrive by Five DC</a>
        </>,
        <>
        <a href='https://ucedd.georgetown.edu/index.php'>University Center for Excellence in Developmental Disability (UCEDD) at Georgetown University</a>
        </>,
      ],
    ]
  }
]
