import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 4
export const pageTitle = 'Important Milestones: Your baby by Four Months'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 4 months by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Smiles spontaneously, especially at people <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20660/20660_lores.jpg']} /></>,
        <>Likes to play with people and might cry when playing stops <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/social/4-months_likes-to-play-with-people.mp4']} /></>,
        <>Copies some movements and facial expressions, like smiling or frowning <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/social/4-months_copies-some-facial-expressions-like-smiling-or-frowning.mp4']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Begins to babble <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/language/4-months_begins-to-babble.mp4']} /></>,
        <>Babbles with expression and copies sounds he hears <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/language/4-months_babbles-with-expression.mp4']} /></>,
        <>Cries in different ways to show hunger, pain, or being tired <ExternalLink icon={faCamera} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/images/4-months-cry-different.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Lets you know if he is happy or sad <ExternalLink icon={faCamera} resources={['https://phil.cdc.gov/PHIL_Images/20661/20661.tif', 'https://phil.cdc.gov/PHIL_Images/20662/20662.tif']} /></>,
        <>Responds to affection <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/cognitive/4-months_responds-to-affection.mp4']} /></>,
        <>Reaches for toy with one hand <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20663/20663_lores.jpg']} /></>,
        <>Uses hands and eyes together, such as seeing a toy and reaching for it <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20674/20674_lores.jpg']} /></>,
        <>Follows moving things with eyes from side to side <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20672/20672_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20673/20673_lores.jpg']} /></>,
        <>Watches faces closely <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20664/20664_lores.jpg']} /></>,
        <>Recognizes familiar people and things at a distance <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/qVDBiDWGmi0']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Holds head steady, unsupported <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20665/20665_lores.jpg']} /></>,
        <>Pushes down on legs when feet are on a hard surface <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20671/20671_lores.jpg']} /></>,
        <>May be able to roll over from tummy to back <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20668/20668_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20669/20669_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20670/20670_lores.jpg']} /></>,
        <>Can hold a toy and shake it and swing at dangling toys <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20666/20666_lores.jpg']} /></>,
        <>Brings hands to mouth <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/MoD-xOT9ExI']} /></>,
        <>When lying on stomach, pushes up to elbows <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20667/20667_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        <>Doesn’t watch things as they move</>,
        <>Doesn’t smile at people</>,
        <>Can’t hold head steady</>,
        <>Doesn’t coo or make sounds</>,
        <>Doesn’t bring things to mouth</>,
        <>Doesn’t push down with legs when feet are placed on a hard surface</>,
        <>Has trouble moving one or both eyes in all directions</>,
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
