import React, { useContext } from 'react'
import { GlobalContext } from '/src/components/GlobalContext'
import { pages } from './constants'
import { Link } from 'gatsby'

export const MilestoneNavbar = ({ ageInMonths }) => {
  const { i18n } = useContext(GlobalContext)
  if (!ageInMonths) { return null }

  return (
    <div className='column is-one-quarter'>
      <aside className="menu">
        <p className="menu-label">
          Milestones
        </p>
        <ul className="menu-list">
          {pages(i18n).map((page, i) => (
            <li key={i}>
              <Link
              to={`/milestones/${page.path}?language=${i18n}`}
              className={page.ageInMonths === ageInMonths ? 'is-active' : null}
            >
                {page.label}
            </Link>
          </li>
          ))}
        </ul>
      </aside>
    </div>
  )
}
