import React from "react";

export const pageTitle = "Evaluación del Desarrollo";
export const pageCopy = [
  {
    body: [
      "La evaluación del desarrollo observa más de cerca cómo se está desarrollando su hijo. Al niño le harán una prueba breve, o usted llenará un cuestionario acerca de su hijo.",
    ],
  },
  {
    title: "¿Quién hace la evaluación del desarrollo?",
    body: [
      "La evaluación del desarrollo puede ser realizada por un médico o enfermera, pero también por otros profesionales de salud, la educación temprana, la comunidad o entornos escolares.",
      <>
        Además, si le preocupa el desarrollo de su hijo, llame a{" "}
        <a href="https://helpmegrow.dc.gov/es">
          Help Me Grow District of Columbia (HMG DC)
        </a>{" "}
        1-800-MOM-BABY (1-800-666-2229) y hable con un coordinador de atención.
        HMC DC ofrece el cuestionario de ‘Ages and Stages’ a las famailias en el
        Distrito de Columbia. Hay dos exámenes de ASQ evaluaciones disponibles:
        general y el desarrollo socioemocional.
      </>,
    ],
  },
  {
    title: "¿Cuándo se debe hacer la evaluación del desarrollo?",
    body: [
      "Si usted o su medico tienen una preocupación, se le debe hacer una evaluación a su hijo. Sin embargo, la evaluación del desarrollo es parte rutinario de algunas visitas médicas para todos los niños, incluso si no hay una preocupación.",
      "El American Academy of Pediatrics (AAP) recomienda las evaluaciones de desarrollo y comportamiento durante visitas rutinarias para todos los niños a las siguientes edades:",
      ["9 meses", "18 meses", "30 meses"],
      "Además, AAP recomienda que todos los niños a las siguientes edades sean evaluados específicamente por un trastorno del espectro autista (TEA) durante las siguientes visitas rutinarias:",
      ["18 meses", "24 meses"],
      "Usted puede usar un ‘pasaporte’ para recordarse de las evaluaciones que ha cumplido su hijo y los resultados.",
    ],
  },
  {
    title: "¿Qué pasa si mi hijo no recibe evaluaciones?",
    body: [
      "Si el medico de su hijo no hace unas evaluaciones del desarrollo de vez en cuando, usted puede pedir que lo haga.",
      "Si el médico de su hijo le dijo que “espere y vea”, pero a usted le inquieta ese consejo:",
      [
        "Hable con otro médico para obtener una segunda opinion, Y",
        "        Llame para solicitar una evaluación gratuita a fin de averiguar si su hijo puede recibir gratis o a bajo costo servicios que pueden ayudar.",
      ],
      <>
        Contacta los programas del Child Find en el Distrito de Columbia,{" "}
        <a href="https://osse.dc.gov/node/1269736">
          Strong Start DC Early Intervention Program
        </a>{" "}
        (si su hijo tiene 0 a 3 años) o{" "}
        <a href="https://www.earlystagesdc.org/Spanish">Early Stages</a> (si su
        hijo tiene dos años, ocho meses a cinco años, diez meses).
      </>,
    ],
  },
  {
    title:
      "¿Qué sucede si la evaluación del desarrollo le causa preocupaciones?",
    body: [
      "Hay muchos tipos de herramientas de evaluación del Desarrollo. Las pruebas de detección para problemas generales del desarrollo a veces se conocen como prueba de detección de nivel 1.",
      "Las pruebas de detección para problemas específicos del desarrollo, por ejemplo, síntomas de autismo a veces se conoce como pruebas de detección de nivel 2. Si una prueba de detección de nivel 1 identifica un problema, el proveedor de atención médica de su hijo o hija puede usar una prueba de detección de nivel 2. Si una prueba de evaluación de nivel 2 identifica un problema, pueden referir a su hijo para evaluaciones adicionales del desarrollo.",
      <>
        Tenga en cuenta que a veces los proveedores se referirán directamente
        para{" "}
        <a href="https://actearlydc.org/evaluations?language=es">
          una evaluación
        </a>{" "}
        después de una prueba de detección de nivel 1.
      </>,
    ],
  },
];
