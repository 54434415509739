import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 12
export const pageTitle = 'Hitos importantes: Su bebé a un año'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final de su primer año completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: '¿Qué hacen los niños a esta edad?: Su hijo de 1 año',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Actúa con timidez o se pone nervioso en presencia de desconocidos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/rkC2Wf4A_nw']} /></>,
        <>Llora cuando la mamá o el papá se van  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/p86vpbe6cOE']} /></>,
        <>Tiene cosas y personas preferida  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20700/20700_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20701/20701_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20702/20702_lores.jpg']} /></>,
        <>Demuestra miedo en algunas situaciones  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/0h1OoeVTHJw']} /></>,
        <>Le alcanza un libro cuando quiere escuchar un cuento <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20703/20703_lores.jpg']} /></>,
        <>Repite sonidos o acciones para llamar la atención  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/HV-SB7iYQtM']} /></>,
        <>Levanta un brazo o una pierna para ayudar a vestirse <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20704/20704_lores.jpg']} /></>,
        <>Juega a ¿Dónde está el bebé? (cucú, peek-a-boo) y a dar palmaditas con las mano <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20706/20706_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20705/20705_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Entiende cuando se le pide que haga algo sencillo  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/tzjCTwrlEqI']} /></>,
        <>Usa gestos simples, como mover la cabeza de lado a lado para decir “no” o mover la mano para decir “adiós”  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/h1xwTJNV0rA']} /></>,
        <>Hace sonidos con cambios de entonación (suena como si fuera lenguaje normal)  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/5eceKJPl8UU']} /></>,
        <>Dice “mamá” y “papá” y exclamaciones como “oh-oh” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/JiGnOH1GTdM']} /></>,
        <>Trata de imitar las palabras que escucha  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/ANzafxAxLW0']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Explora los objetos de diferentes maneras (los sacude, los golpea o los tira) <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/pMVinh7qvT8']} /></>,
        <>Encuentra fácilmente objetos escondidos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20707/20707_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20708/20708_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20709/20709_lores.jpg']} /></>,
        <>Cuando se nombra algo, mira en dirección a la ilustración o cosa que se nombró  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/rQ6RuauvK3Y']} /></>,
        <>Copia gestos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20710/20710_lores.jpg']} /></>,
        <>Comienza a usar las cosas correctamente, por ejemplo, bebe de una taza, se cepilla el pelo  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/9nyrA0e9BGE']} /></>,
        <>Golpea un objeto contra otro  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/UVoVM0k065Q']} /></>,
        <>Mete cosas dentro de un recipiente, las saca del recipiente  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/gHYUR7nI8mI']} /></>,
        <>Suelta las cosas sin ayuda  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/33E6N81FKDk']} /></>,
        <>Toca a las personas y las cosas con el dedo índice  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20711/20711_lores.jpg']} /></>,
        <>Sigue instrucciones sencillas como “recoge el juguete”  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/pFy0ZT7aLjk']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Se sienta sin ayuda <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20712/20712_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20713/20713_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20714/20714_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20715/20715_lores.jpg']} /></>,
        <>Hala para ponerse de pie y camina apoyándose en los muebles.  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/AcNzdSTBhHI']} /></>,
        <>Puede que dé unos pasos sin apoyarse  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/hrTXjv3TjX4']} /></>,
        <>Puede ser que se pare solo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20716/20716_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        'No gatea',
        'No puede permanecer de pie con ayuda',
        'No busca cosas que ha visto que usted ha escondido',
        'No dice palabras sencillas como “mamá” o “papá”',
        'No aprende a usar gestos como saludar con la mano o mover la cabeza',
        'No señala cosas',
        'Pierde habilidades que había adquirido',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
