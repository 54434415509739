import React, { useContext } from "react";
import SimplePage from "/src/components/SimplePage";
import { GlobalContext } from "/src/components/GlobalContext";
import * as en from "/src/locales/en/parent_resources";
import * as es from "/src/locales/es/parent_resources";

const ParentResources = ({}) => {
  const { i18n } = useContext(GlobalContext);
  const copy = i18n === "en" ? en : es;

  return (
    <>
      <SimplePage title={copy.pageTitle} copy={copy.pageCopy} />
    </>
  );
};

export default ParentResources;
