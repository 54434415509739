import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 48
export const pageTitle = 'Indicadores importantes: su bebé a los 4 años'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final de los 4 años completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: 'Lo que la mayoría de los niños hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Disfruta haciendo cosas nuevas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Gr9KyV1xKBQ']} /></>,
        <>Juega a “papá y mamá” <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20764/20764_lores.jpg']} /></>,
        <>Cada vez se muestra más creativo en los juegos de imaginación  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/t14sEYj8kAQ']} /></>,
        <>Le gusta más jugar con otros niños que jugar solo  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/ZsB56sZP95g']} /></>,
        <>Juega en cooperación con otros <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20765/20765_lores.jpg']} /></>,
        <>A menudo, no puede distinguir la fantasia de la realidad  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/t_XG_3FSh68']} /></>,
        <>Habla acerca de lo que le gusta y le interesa  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6yWd2dDF2nU']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Sabe algunas reglas básicas de gramática, como el uso correcto de “él” y “ella”  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/hEHvgGS0B3Y']} /></>,
        <>Canta una canción o recita un poema de memoria  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Z4cKlVpzAbc']} /></>,
        <>Relata cuentos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/iUTtaGVC8PY']} /></>,
        <>Puede decir su nombre y apellido</>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Nombra algunos colores y algunos números  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/s6fmOSMzxY8']} />&nbsp;&nbsp;<ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/q7gVDZbT6QA']} /></>,
        <>Entiende la idea de contar  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/e9U3qMmpd3E']} /></>,
        <>Comienza a entender el concepto del tiempo  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/SYBOIxJTbQM']} /></>,
        <>Recuerda partes de un cuento  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/f-QCjY5caTw']} /></>,
        <>Entiende el concepto de “igual” y “diferente”  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/wjT7MGW0TnI']} /></>,
        <>Dibuja a una persona con 2 a 4 partes del cuerpo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20766/20766_lores.jpg']} /></>,
        <>Usa tijeras <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20767/20767_lores.jpg']} /></>,
        <>Empieza a copiar algunas letras mayúsculas  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/c2v0jzIrNjE']} /></>,
        <>Juega juegos infantiles de mesa o de cartas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20768/20768_lores.jpg']} /></>,
        <>Le dice lo que le parece que va a suceder a continuación en un libro  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/d92mzpawjAE']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Brinca y se sostiene en un pie hasta por 2 segundos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20769/20769_lores.jpg']} /></>,
        <>La mayoría de las veces agarra una pelota que rebota <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20770/20770_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20771/20771_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20772/20772_lores.jpg']} /></>,
        <>Se sirve los alimentos, los corta con supervisión y los hace papilla <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20773/20773_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        'No puede saltar en el mismo sitio',
        'Tiene dificultades para hacer garabatos',
        'No muestra interés en los juegos interactivos o de imaginación',
        'Ignora a otros niños o no responde a las personas que no son de la familia',
        'Rehúsa vestirse, dormir y usar el baño',
        'No puede relatar su cuento favorito',
        'No sigue instrucciones de 3 partes',
        'No entiende lo que quieren decir “igual” y “diferente”',
        'No usa correctamente las palabras “yo” y “tú”',
        'Habla con poca claridad',
        'Pierde habilidades que había adquirido',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
