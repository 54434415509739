import React from 'react'
import { Link } from 'gatsby'

export const pageTitle = 'Developmental Monitoring'
export const pageCopy = [
  {
    body: [
      "Research studies have confirmed that parents are reliable sources of information about their child’s development. Parents who are aware of developmental milestones can observe their child and inform their healthcare provider about any concerns they may have about their child’s development."
    ]
  },
  {
    title: 'What are developmental milestones?',
    body: [
      <>
      <Link to='/milestones?language=en'>Developmental Milestones</Link> are things most children can do by a certain age.
      </>
    ]
  },
  {
    title: 'What is developmental monitoring?',
    body: [
      "Developmental monitoring observes how your child grows and changes over time and whether your child meets the typical developmental milestones in playing, learning, speaking, behaving, and moving.",
    ]
  },
  {
    title: 'Who can participate in developmental monitoring?',
    body: [
      "Parents, grandparents, early childhood providers, and other caregivers can participate in developmental monitoring.",
    ]
  },
  {
    title: 'Your Role as a Parent:',
    body: [
      'If your child’s healthcare provider does not periodically check your child with a developmental screening test, you can ask that it be done.', 
      [
        "Help your child learn and grow by tracking their developmental milestones and using positive parenting tips.",
        "If your child is not reaching these milestones, or reaching them much later than other children, it could be a sign of a developmental delay. Talk to your child’s doctor and ask for a developmental screening." ,
        <>
        If you are concerned about your child’s development, do not wait.  Contact DC’s Child Find programs, <a href='https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip'>Strong Start DC Early Intervention Program</a> (if your child is age 0-3 years) or <a href='https://www.earlystagesdc.org/'>Early Stages</a> (if your child is between 2 years, 8 month and 5 years 10 months).  Additionally, you can call <a href='https://helpmegrow.dc.gov/'>Help Me Grow District of Columbia</a> (HMG DC) at 1-800-MOM-BABY (1-800-666-2229) and speak with a care coordinator.
        </>
      ]
    ]
  }
]
