import React from 'react'

export const pageTitle = 'Preguntas frecuentes'
export const pageCopy = [
  {
    title: '¿Qué es el monitoreo y la evaluación del desarrollo?',
    body: [
      'El crecimiento y el desarrollo de un niño se siguen a través de una colaboración entre los padres y los profesionales de salud. En cada visita, el médico observa por retrasos en el desarrollo y habla con los padres sobre cualquier preocupación que tengan. Esto se llama monitoreo del desarrollo.',
      'Cualquier problema que se note durante el monitoreo del desarrollo debe ser seguido con la evaluación del desarrollo. La evaluación del desarrollo es una prueba corta para saber si un niño está aprendiendo habilidades básicas cuando debería, o si hay retrasos.  La evaluación del desarrollo puede ser realizada por un médico o enfermera, pero también por otros profesionales de salud, la educación temprana, la comunidad o entornos escolares.',
      'Si un niño tiene un retraso en el desarrollo, es importante obtener ayuda lo antes posible. La identificación e intervención tempranas pueden tener un impacto significativo en la capacidad de un niño para aprender nuevas habilidades, así como reducir la necesidad de intervenciones costosas a largo plazo',
      <>
        <a href='https://www.cdc.gov/ActEarly'>Contenido adaptado de "Aprende los Signos. Actúa temprano". Programa, Centros para el Control y la Prevención de Enfermedades</a>
      </>
    ]
  },
  {
    title: '¿Por qué es importante la identificación e intervención temprana?',
    body: [
      'De acuerdo con el Centro para el Control y la Prevención de Enfermedades, aproximadamente uno de cada seis niños tiene algún tipo de discapacidad del desarrollo. Esto incluye cualquier cosa, desde parálisis cerebral hasta trastorno del espectro autista y una deficiencia menor del habla. Muchos niños con una discapacidad del desarrollo no son identificados hasta después de comenzar la escuela.  La intervención temprana (antes de la edad escolar) puede tener un gran impacto en la capacidad de un niño para aprender nuevas habilidades, así como reducir la necesidad de intervenciones costosas a lo largo plazo.',
      <>
        <a href='https://www.cdc.gov/ActEarly'>Contenido adaptado de "Aprende los Signos. Actúa temprano". Programa, Centros para el Control y la Prevención de Enfermedades</a>
      </>
    ]
  },
  {
    title: '¿Qué son las discapacidades del desarrollo?',
    body: [
      "Las discapacidades del desarrollo son un grupo de condiciones debido a un impedimento en las áreas físicas, de aprendizaje, de lenguaje o de comportamiento. Estas condiciones comienzan durante el período de desarrollo, pueden afectar el funcionamiento diario y, por lo general, duran lo largo de la vida de una persona. ",
      <>
        <a href='https://www.cdc.gov/ncbddd/developmentaldisabilities/facts.html'>Contenido adaptado de "Discapacidades del desarrollo. "  Centros para el Control y la Prevención de Enfermedades</a>
      </>
    ]
  },
  {
    title: '¿Qué es el Autismo?',
    body: [
      "El trastorno del espectro autista (TEA) es una discapacidad del desarrollo que puede causar dificultades sociales, de comunicación y de comportamiento.  Los niños diagnosticados con TEA pueden comunicarse, interactuar, comportarse y aprender de maneras que son diferentes a las personas sin un diagnóstico de TEA.  Algunos niños  pueden tener habilidades en el aprendizaje, el pensamiento y la resolución de problemas que van desde superdotados hasta tener deficiencias significativas en el funcionamiento, y pueden necesitar mucho apoyo en su vida diaria, mientras que otros simplemente no lo necesiten.",
      <>
        <a href='http://www.cdc.gov/ncbddd/autism/facts.html'>Contenido adaptado de "Autismo". Centros para el Control y la Prevención de Enfermedades</a>
      </>
    ]
  },
  {
    title: "¿Qué sucede después de que mi hijo es diagnosticado?",
    body: [
      'Investigaciones muestran que los servicios de tratamiento de intervención temprana pueden mejorar el desarrollo y la capacidad de un niño para aprender nuevas habilidades. Los servicios pueden incluir diferentes tipos de terapias para ayudar a hablar, caminar e interactuar con los demás. Es importante hablar con el médico de su hijo lo antes posible si usted tiene una preocupación por su desarrollo.',
      <>
        Su hijo puede calificar para los servicios de tratamiento de intervención temprana, incluso si no tiene un trastorno del espectro autista o diagnóstico más allá de un retraso en el desarrollo. Esto se debe a  la Ley de Educación para Individuos <a href='http://idea.ed.gov/'>con Discapacidades (IDEA</a>, por sus siglas en inglés), que dice que los niños menores de 3 años que muestran signos de posibles retrasos en el desarrollo pueden ser elegibles para servicios. Los servicios de intervención temprana se proporcionan a través del Programa de Intervención Temprana Strong Start de <a href='https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip'>DC</a>.,
      </>,
      <>
        <a href='http://www.cdc.gov/ncbddd/autism/facts.html'>Contenido adaptado de "Autismo". Centros para el Control y la Prevención de Enfermedades</a>
      </>
    ]
  },
  {
    title: "¿Qué es Act Early DC?",
    body: [
      'Act Early DC es una campaña de servicio público para apoyar y colaborar con los programas de educación temprana de DC que apoyan a las familias y al desarrollo infantil para avanzar el monitoreo del desarrollo y apoyar la evaluación del desarrollo.  No proveemos servicios, pero trabajamos en DC para llevar a cabo la campaña "Aprende los Signos. Actúa temprano." del Centro para el Control y la Prevención de Enfermedades para educar a las familias y profesionales sobre los hitos del desarrollo y la importancia de las evaluaciones del desarrollo y actuar cuando hay una preocupación con el desarrollo de un bebé, niño pequeño o niño.'
    ]
  }
]
