import React from 'react'
import { Link } from 'gatsby'

export const pageTitle = 'Seguimiento Del Desarrollo'
export const pageCopy = [
  {
    body: [
      "Los estudios de investigación han confirmado que los padres son fuentes confiables de informcaión sobre el desarrollo de su hijo. Los padres que conocen los hitos del desarrollo pueden observer a su hijo e informar a su proveedor de atención médica sobre cualquier inquietud que puedan tener sobre el desarrollo de su hijo."
    ]
  },
  {
    title: '¿Qué son los hitos del desarrollo?  ',
    body: [
      <>
      <Link to='/milestones?language=es'>Los indicadores del desarrollo</Link> son las cosas que la mayoría de los niños de una edad específica pueden hacer.
      </>
    ]
  },
  {
    title: '¿Qué es el monitoreo del desarrollo?',
    body: [
      "En el monitoreo del desarrollo se observa cómo su hijo crece y cambia con el tiempo y si su niño está alcanzando los típicos indicadores del desarrollo al jugar, aprender, hablar, actuar y moverse.",
    ]
  },
  {
    title: '¿Quién puede participar en el monitoreo del desarrollo?',
    body: [
      "Los padres, abuelos, proveedores de educación temprana y otros cuidadores pueden participar en el monitoreo del desarrollo.",
    ]
  },
  {
    title: 'Su papel como padre:',
    body: [
      [
        "Si el proveedor de atención médica de su hijo no revisa periódicamente a su hijo con una prueba de detección del desarrollo, puede solicitar que se haga.",
        "Ayude a su hijo aprender y crecer haciendo un seguimiento de sus hitos de desarrollo y utilizando consejos positivos para padres",
        "Si su hijo no está alcanzando estos hitos, o si los alcanza mucho más tarde que otros niños, podría ser un signo de un retraso en su desarrollo.",
        "Hable con el médcio de su hijo y pide un examen del Desarrollo.",
        <>
        Si le preocupa el desarollo de su hijo, no espere. Contacta los programas del Child Find en el Distrito de Columbia <a href='https://osse.dc.gov/node/1269736'>Strong Start DC Early Intervention Program</a> (si su hijo tiene entre 0 a 3 años) o <a href='https://www.earlystagesdc.org/Spanish'>Early Stages</a> (si su hijo tiene entre 2 años, 8 meses y 5 años, 10 meses). Además, usted puede llamar <a href='https://helpmegrow.dc.gov/es'>Help Me</a> Grow District of Columbia (HMG DC) a 1-800-MOM-BABY (1-800-666-2229) y hablar con un coordinador de atención.
        </>
      ]
    ]
  }
]
