import React from 'react'
import { MilestoneNavbar } from '../components/MilestoneNavbar'

export const SimplePage = ({ title, copy, ageInMonths }) => {
  const offset = !ageInMonths ? 'is-offset-one-fifth' : null
  return(
    <section className='section'>
      <div className='columns'>
        <MilestoneNavbar ageInMonths={ageInMonths} />
        <div className={`column is-three-fifths ${offset}`}>
          <div className='content'>
            {title &&
            <h1 className='title is-3 has-text-primary is-family-sans-serif pb-4'>
              {title}
            </h1>
            }
            {copy.map((section, i) =>
              <div className='content' key={i}>
                {section.title &&
                <h2 className={`title ${section.body.length === 0 ? 'is-4' : 'is-5'} ${section.body.length === 0 ? 'pb-3' : null} has-text-primary is-family-secondary`}>
                  {section.title}
                </h2>
                }
                {section.body.map((body, i) => {
                  if (Array.isArray(body)) {
                    return <ul key={i}>
                      {body.map((bullet, i) => <li key={i}>{bullet}</li>)}
                    </ul>
                  } else { return <div className='content' key={i}>{body}</div> }
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SimplePage
