import React from "react";
export const pageTitle = "Autism Resources";
export const pageCopy = [
  {
    title: "What is Autism?",
    body: [
      <>
        Autism or autism spectrum disorder (ASD) is a neurodevelopmental
        disability that can be associated with social, communication, and
        behavioral challenges. Children diagnosed with autism may communicate,
        interact, behave, and learn in ways that are different from people
        without autism. You can visit the{" "}
        <a href="https://www.cdc.gov/ncbddd/autism/facts.html">
          Centers for Disease Control and Prevention (CDC)
        </a>{" "}
        autism webpage to learn more about how autism is defined.
      </>,
    ],
  },
  {
    title: "How Common is Autism?",
    body: [
      <>
        The Centers for Disease Control and Prevention (CDC) estimates that
        about 1 in 44, 8-year-old children have been identified with autism. You
        can view the CDC Autism and Developmental Disabilities Monitoring
        Network’s{" "}
        <a href="https://www.cdc.gov/ncbddd/autism/addm-community-report/index.html">
          2021 Community Report
        </a>{" "}
        to learn more.
      </>,
    ],
  },
  {
    title: "How is Autism Diagnosed?",
    body: [
      <>
        A diagnosis of autism is made based on your description of your child’s
        development, a review of your child’s history and observations of
        certain behaviors by your child’s primary care provider and an autism
        expert. You can learn more by reading the American Academy of
        Pediatrics’ article,{" "}
        <a href="https://www.healthychildren.org/English/health-issues/conditions/Autism/Pages/Diagnosing-Autism.aspx?_ga=2.61781861.877485078.1632228743-34439519.1632228743&_gl=1*tnq54o*_ga*MzQ0Mzk1MTkuMTYzMjIyODc0Mw..*_ga_FD9D3XZVQQ*MTYzMjIyODc0Mi4xLjEuMTYzMjIyODkxMi4w">
          “How is Autism Diagnosed?”
        </a>
      </>,
      <b>The criteria for diagnosing autism include:</b>,
      [
        "challenges with social communication and interaction",
        "restricted or repetitive behaviors or interests",
      ],
    ],
  },
  {
    title: "What are the Early Signs of Autism? ",
    body: [
      <>
        It is important to note that most children with autism engage in just
        some, but not all, of the behaviors listed. The CDC has a list of the
        signs of autism that can be found{" "}
        <a href="https://www.cdc.gov/ncbddd/autism/signs.html ">here.</a>
      </>,
      <>
        The American Academy of Pediatrics has a similar list,{" "}
        <a href="https://www.healthychildren.org/English/health-issues/conditions/Autism/Pages/Early-Signs-of-Autism-Spectrum-Disorders.aspx">
          “What are the Early Signs of Autism?”
        </a>{" "}
        that includes examples of how to tell the difference between a child
        with autism and a typically developing child. Autism Navigator developed
        the{" "}
        <a href="https://babynavigator.com/16-early-signs-of-autism-by-16-months-chinese/#fb0=125">
          Baby Navigator Lookbook: 16 Early Signs of Autism by 16 Months.
        </a>
      </>,
      <>
        Kennedy Krieger Institute has developed a free{" "}
        <a href="https://www.kennedykrieger.org/patient-care/centers-and-programs/center-for-autism-and-related-disorders/outreach-and-training/early-signs-of-autism-video-tutorial">
          9-minute video tutorial
        </a>
        to improve recognition of the early signs of autism. The tutorial
        consists of 6 video clips comparing toddlers who show no signs of autism
        to toddlers who show early signs of autism.
      </>,
    ],
  },
  {
    title: "If I Suspect Autism, What Do I Do Next? ",
    body: [
      <>
        If you suspect autism, there are several options for next steps. It is
        often helpful to pursue multiple pathways at the same time. This
        includes seeking out an Early Intervention or educational evaluation
        (Strong Start or Early Stages). Seek out an evaluation within the
        medical setting. Talk to your child’s primary care provider about these
        options. If your child’s primary care provider has told you to “wait and
        see,” but you feel uneasy about that advice, you can self-refer to{" "}
        <a href="https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip">
          Strong Start
        </a>{" "}
        or <a href="https://www.earlystagesdc.org/">Early Stages</a>
      </>,
    ],
  },
  {
    title: "Early Intervention/ Educational Evaluations ",
    body: [
      <>
        Guided by the{" "}
        <a href="https://sites.ed.gov/idea/regs/b/a/300.8/c/1">
          Individuals with Disabilities Education Act (IDEA) Part C
        </a>{" "}
        and{" "}
        <a href="https://sites.ed.gov/idea/statute-chapter-33/subchapter-ii/1414">
          Part B, Strong Start
        </a>{" "}
        and <a href="https://www.earlystagesdc.org/">Early Stages</a>
        {"  "}
        are free to all families within the District of Columbia. An evaluation
        is done to determine if the child is eligible for services.
      </>,
      [
        <>
          For children birth to 2 years, 10 months,{" "}
          <a href="https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip">
            Strong Start
          </a>{" "}
          provides services by an Individualized Family Service Plan (IFSP) if
          there is a 25% delay in 1-5 areas of development. Even though they do
          not diagnose or classify children as having autism, they do provide
          important intervention services that many children with autism need,
          such as speech therapy, occupational therapy, and applied behavior
          analysis.
        </>,
        <>
          For children 2 years, 8 months to 5 years, 10 months,{" "}
          <a href="https://www.earlystagesdc.org/">Early Stages’</a> evaluations
          are used to determine if a child meets criteria for one of the 14
          IDEA-specified disabilities which includes autism. Early Stages will
          create and recommend Individualized Education Plan (IEP) services. IEP
          services are implemented in a DC public school setting or DC Public
          Charter School.
        </>,
      ],
    ],
  },
  {
    title: "Evaluations of Autism within the Medical Setting ",
    body: [
      <>
        Children can receive a comprehensive autism evaluation in the medical
        setting by a medical provider (pediatrician, psychologist, or another
        medical specialist). These evaluations are usually covered by your
        child’s health insurance. Providers use your description of your child’s
        development, developmental history, observations, and assessments to
        determine if your child meets the{" "}
        <a href="https://www.cdc.gov/ncbddd/autism/hcp-dsm.html">
          DSM—5 diagnostic criteria
        </a>{" "}
        for ASD. If your child meets these criteria, the provider will give your
        child a <b>medical diagnosis of autism.</b>
      </>,
    ],
  },
  {
    title:
      "What are the Differences Between a Medical Diagnosis and an Educational Classification of Autism?",
    body: [
      <>
        Usually made by a medical provider in a healthcare setting using{" "}
        <a href="https://www.cdc.gov/ncbddd/autism/hcp-dsm.html">
          DSM—5 diagnostic criteria
        </a>{" "}
        a medical diagnosis of autism will not always lead to eligibility for
        early intervention/special education services. A medical diagnosis of
        autism can be helpful to make sure a child has additional access to
        services through their health insurance plan. A medical provider may be
        able to recommend other therapies and services that are not a part of
        the IFSP or IEP.
      </>,
      "For children 2 years, 8 months of age and older, an educational classification of autism identifies autism as a disability under IDEA and determines eligibility for special education services. An educational classification of autism can be helpful to make sure a child has access to needed supports in school.",
    ],
  },
  {
    title:
      "Why is it important to pursue both a Medical Diagnosis and an Educational Classification of Autism? ",
    body: [
      "Families can have better access to services in school and in the community and may be able to support their child’s development more comprehensively.",
    ],
  },
  {
    title: "How do I Access Early Intervention/Educational Evaluations? ",
    body: [
      "Your child’s primary care provider may refer you or you can self-refer to Strong Start or Early Intervention.",
      [
        <>
          If your child is birth to 2 years, 10 months: Call the{" "}
          <a href="https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip">
            Strong Start DC Early Intervention Program
          </a>{" "}
          Hotline at (202) 727-3665 or fill out the referral form
        </>,
        <>
          If your child is 2 years, 8 month and 5 years, or 10 months: Call{" "}
          <a href="https://www.earlystagesdc.org/">Early Stages</a> at
          (202)-698-8037 or submit a{" "}
          <a href="https://www.earlystagesdc.org/form/refer-a-child">
            referral form
          </a>{" "}
          here.
        </>,
      ],
      "Please note that the early intervention/special education evaluation process will only begin after a parent/guardian has provided written consent.",
    ],
  },
  {
    title:
      "WHERE CAN I GET MY CHILD EVALUATED FOR A MEDICAL DIAGNOSIS OF AUTISM? ",
    body: [
      "You can call your insurance provider or ask your child’s doctor to help you find a health care provider who can diagnose autism. Below is also a list of clinics that evaluate autism:",
      <b>DC Metro Area:</b>,
      [
        <>
          <a href="https://childrensnational.org/healthcare-providers/refer-a-patient/clinic-guidelines/autism-and-neurodevelopmental-referral-guidelines">
            Children’s National Hospital: Various Autism Clinics
          </a>
        </>,
        <>
          <a href="https://www.medstarhealth.org/services/autism-and-communications-disorder-clinic">
            MedStar Georgetown University Hospital: Autism and Communications
            Disorders Clinic
          </a>{" "}
          202-444-2722
        </>,
      ],
      <b>Baltimore Area:</b>,
      [
        <>
          <a href="https://www.kennedykrieger.org/patient-care/centers-and-programs/center-for-autism-and-related-disorders">
            Center for Autism and Related Disorders at Kennedy Krieger Institute
          </a>{" "}
          443-923-7630, option 2
        </>,
        <>
          <a href="https://www.mwph.org/health-services/behavioral-health/autism-spectrum-center">
            Autism Spectrum Center at Mount Washington Pediatric Hospital
          </a>{" "}
          410-367-2222
        </>,
      ],
    ],
  },
  {
    title: "Parent Tips on How to Access Autism Evaluations: ",
    body: [
      [
        "Waitlists are long for medical evaluations (3 months-2 years), but do not get discouraged",
        "Call multiple clinics and submit paperwork, you can always call to cancel once you get an appointment",
        "Medical evaluation and early intervention/educational evaluations can happen at the same time, and it is often a good idea to pursue both at the same time",
        "Ask questions if you do not understand",
        "Work with your medical provider to arrange further diagnostic evaluations if needed",
      ],
    ],
  },
  {
    title:
      "If you need support in accessing or navigating these services, contact:",
    body: [
      [
        <a href="https://www.aje-dc.org/">
          Advocates for Justice and Education
        </a>,
        <a href="https://childrenslawcenter.org/">Children’s Law Center</a>,
        <a href="https://www.dcautismparents.org/">DC Autism Parents</a>,
      ],
      <>
        The American Academy of Pediatrics has created a resource,{" "}
        <a href="https://www.healthychildren.org/English/health-issues/conditions/Autism/Pages/If-Autism-is-Suspected-Whats-Next.aspx?_ga=2.125948740.877485078.1632228743-34439519.1632228743&_gl=1*111y09d*_ga*MzQ0Mzk1MTkuMTYzMjIyODc0Mw..*_ga_FD9D3XZVQQ*MTYzMjIyODc0Mi4xLjEuMTYzMjIyODk1MS4w">
          “If Autism is Suspected, What Next?”
        </a>{" "}
        which provides information on what happens during an autism evaluation,
        as well as a list of medical tests that are not recommended.
      </>,
    ],
  },
  {
    title: "What should I do after an evaluation? ",
    body: [
      <>
        Parents should keep records of conversations and communications with
        providers and agencies. The Center for Parents Information and Resources
        created this{" "}
        <a href="https://www.parentcenterhub.org/wp-content/uploads/repo_items/recordkeeping.pdf">
          Sample Record Keeping Worksheet.
        </a>
        Parents should get a copy of the evaluation and share this with their
        child’s health and education providers.
      </>,
      <>
        It also will be important to connect with other parents/caregivers of
        children diagnosed with autism and connect with community resources.
        Check out this blog post entitled{" "}
        <a href="https://www.dcautismparents.org/blog/the-first-three-things-to-do-when-your-child-is-diagnosed-with-autism">
          “The First Three Things to Do When Your Child is Diagnosed with
          Autism”
        </a>
        to help get you started.
      </>,
    ],
  },
  {
    title: "Autism Resources for Families:",
    body: [
      <>
        <a href="https://www.dcautismparents.org/">DC Autism Parents (DCAP)</a>{" "}
        is 501(c)3, parent-volunteer run non-profit organization created by
        parents of children with autism for parents of children with autism.
        DCAP offers a monthly newsletter, support group calls, and training for
        families. To sign up for our newsletter, please click{" "}
        <a href="https://lp.constantcontactpages.com/su/GFlfc5x">here.</a>
      </>,
      <>
        <b>Parent Training and Information Center:</b> Each state has at least
        one Parent Training and Information (PTI) Center funded under the
        Individuals with Disabilities in Education Act (IDEA). The goal of the
        PTI is to support families of children birth-22 with disabilities by
        providing free information to maximize their child’s education. DC’s PTI
        is{" "}
        <a href="https://www.aje-dc.org/">Advocates for Justice & Education.</a>
      </>,
      <>
        <b>Family-to-Family Health Information Center (F2F):</b>{" "}
        <a href="https://www.aje-dc.org/">
          Advocates for Justice & Education (AJE)
        </a>{" "}
        is DC’s F2F, which is family-led centers funded by the Health Resources
        and Services Administration. There is one F2F in each state, and each
        F2F is staffed by highly skilled, knowledgeable family members who have
        first-hand experience and understanding of the challenges faced by
        families of Children and Youth with Special Health Care Needs.
      </>,

      <>
        <b>Parent to Parent (P2P):</b> Parent to Parent USA is a national
        network of P2P programs to ensure access to quality emotional support
        for families of individuals with disabilities and/or special health care
        needs. Through a one-to-one “match,” experienced Support Parents provide
        emotional support to families of individuals with special health care
        needs and assist them in finding information and resources.{" "}
        <a href="https://www.dcqualitytrust.org/family-ties/">
          Family Ties of DC
        </a>{" "}
        is the parent to parent program for District of Columbia parents and
        caregivers of children with disabilities.
      </>,
      <>
        <b>Developmental Disabilities (DD) Council:</b>{" "}
        <a href="https://ddc.dc.gov/">The DC DD Council</a> is an independent,
        community-based advisory committee funded by the U.S. Department of
        Health and Human Services, Administration on Intellectual and
        Developmental Disabilities. The DD Council seeks to strengthen the voice
        of people with developmental disabilities and their families in support
        of greater independence, inclusion, empowerment, and the pursuit of life
        as they choose.
      </>,
      <>
        <b>Partners in Policymaking (PIP):</b> PIP is a program that prepares
        adults with intellectual/ developmental disabilities (I/DD), parents,
        and family members of children and adults with I/DD to be effective
        advocates at the local, state, and federal levels. This program is
        currently available in over 30 states, including DC.{" "}
        <a href="https://dcpartners.iel.org/">DC Advocacy Partners (DC AP)</a>{" "}
        is DC’s PIP Program. DC AP is a leadership training program designed for
        self advocates and family members of individuals with intellectual
        and/or developmental disabilities as well as professionals working with
        people with disabilities.
      </>,
      <i>
        This content was developed by Yetta Myrick, CDC’s Act Early Ambassador
        to DC and was reviewed and edited by the DC Autism Collaborative
        Developmental Monitoring, Screening, and Evaluation Subgroup: Emily
        Aaron, Azeb Adere, Shanter Alexander, Jane Anderson, Trina Anderson,
        Rachel Brady, Tori Bronaugh, Ivy Giserman-Kiss, Leandra Godoy, Yolanda
        Graves, Samantha Hamburger, Amanda Hastings, Diane Jacobstein, Shalinee
        Khurana, Stacie Lamour, Becky Lane, Melissa Long, Michael Mintz, Yetta
        Myrick, Allan Phillips, Jordanne Reader, Karla Reid-Witt, Liz Rihani,
        Cathy Scheiner, Audrey Thurm, Yagnash Vadgama, Mary Wozniak
      </i>,
    ],
  },
];
