import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 24
export const pageTitle = 'Important Milestones: Your baby by 2 Years'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 2 years by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Copies others, especially adults and older children <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20728/20728_lores.jpg']} /></>,
        <>Gets excited when with other children <ExternalLink icon={faCamera} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/images/getsExcitedWhenWithChildren.jpg']} /></>,
        <>Shows more and more independence <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20729/20729_lores.jpg']} /></>,
        <>Shows defiant behavior (doing what he has been told not to) <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/HaXnKsnlohk']} /></>,
        <>Plays mainly beside other children, but is beginning to include other children, such as in chase games <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/aau2i8hIQaY']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Points to things or pictures when they are named <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/o9vfRS7x53c']} /></>,
        <>Knows names of familiar people and body parts <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/o-icLJC6tHc']} /></>,
        <>Says sentences with 2 to 4 words <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/rIdCA-imsZU']} /></>,
        <>Follows simple instructions <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/enEGavf5Cn8']} /></>,
        <>Repeats words overheard in conversation <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/7WoNafM_RIk']} /></>,
        <>Points to things in a book <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20730/20730_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Finds things even when hidden under two or three covers <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20731/20731_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20732/20732_lores.jpg']} /></>,
        <>Begins to sort shapes and colors <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20733/20733_lores.jpg']} /></>,
        <>Completes sentences and rhymes in familiar books <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/e_X8gip0ikQ']} /></>,
        <>Plays simple make-believe games <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20734/20734_lores.jpg']} /></>,
        <>Builds towers of 4 or more blocks <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20735/20735_lores.jpg']} /></>,
        <>Might use one hand more than the other <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/RIUgcQSHAVc']} /></>,
        <>Follows two-step instructions such as “Pick up your shoes and put them in the closet.” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/tySEn371aZM']} /></>,
        <>Names items in a picture book such as a cat, bird, or dog <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/HFg1eSu_KTk']} /></>
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Stands on tiptoe <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20736/20736_lores.jpg']} /></>,
        <>Kicks a ball <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20737/20737_lores.jpg']} /></>,
        <>Begins to run <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6vnTQD0qqGU']} /></>,
        <>Climbs onto and down from furniture without help <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20738/20738_lores.jpg']} /></>,
        <>Walks up and down stairs holding on <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20740/20740_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20739/20739_lores.jpg']} /></>,
        <>Throws ball overhand <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20741/20741_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20741/20741_lores.jpg']} /></>,
        <>Makes or copies straight lines and circles <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6XV8xXdy0lg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        "Doesn’t use 2-word phrases (for example, 'drink milk')",
        "Doesn’t know what to do with common things, like a brush, phone, fork, spoon",
        "Doesn’t copy actions and words",
        "Doesn’t follow simple instructions",
        "Doesn’t walk steadily",
        "Loses skills she once had",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
