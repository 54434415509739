
import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 4
export const pageTitle = 'Indicadores importantes: su bebé a los 4 meses'
export const pageCopy = [
  {
    body: [
      <>
      Verifique los hitos que su hijo ha alcanzado al final de los 4 meses completando una lista de verificación con la aplicación móvil gratuita <a href='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> de los CDC, para dispositivos <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>iOS</a> y <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a>, o imprimiendo la lista de verificación a continuación. Lleve la lista de verificación con usted y hable con el médico de su hijo en cada visita sobre los hitos que su hijo ha alcanzado y qué esperar a continuación.
      </>
    ]
  },
  {
    title: 'Lo que la mayoría de los bebés hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Sonríe espontáneamente, especialmente a las personas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20651/20651_lores.jpg']} /></>,
        <>Le gusta jugar con la gente y puede ser que hasta llore cuando se terminan los juegos  <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/social/4-months_likes-to-play-with-people.mp4']} /></>,
        <>Copia algunos movimientos y gestos faciales, como sonreír o fruncir el ceño  <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/social/4-months_copies-some-facial-expressions-like-smiling-or-frowning.mp4']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Empieza a balbucear <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/language/4-months_begins-to-babble.mp4']} /></>,
        <>Balbucea expresivamente e imita los sonidos que escucha <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/language/4-months_babbles-with-expression.mp4']} /></>,
        <>Llora de diferentes maneras para mostrar cuando tiene hambre, siente dolor o está cansado <ExternalLink icon={faCamera} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/images/4-months-cry-different.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Sonríe espontáneamente, especialmente a las personas  <ExternalLink icon={faCamera} resources={['https://phil.cdc.gov/PHIL_Images/20661/20661.tif', 'https://phil.cdc.gov/PHIL_Images/20662/20662.tif']} /></>,
        <>Responde ante las demostraciones de afecto  <ExternalLink icon={faVideo} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/videos/4months/cognitive/4-months_responds-to-affection.mp4']} /></>,
        <>Trata de alcanzar los juguetes con una mano  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20663/20663_lores.jpg']} /></>,
        <>Usa las manos y los ojos al mismo tiempo, como cuando ve un juguete y trata de alcanzarlo  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20674/20674_lores.jpg']} /></>,
        <>Sigue con la vista a las cosas que se mueven, moviendo los ojos de lado a lado <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20672/20672_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20673/20673_lores.jpg']} /></>,
        <>Observa las caras con atención  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20664/20664_lores.jpg']} /></>,
        <>Reconoce objetos y personas conocidas desde lejos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/qVDBiDWGmi0']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      [
        <>Mantiene la cabeza fija, sin apoyo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20665/20665_lores.jpg']} /></>,
        <>Se empuja con las piernas cuando tiene los pies sobre una superficie firme <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20671/20671_lores.jpg']} /></>,
        <>Cuando está boca abajo puede darse vuelta y quedar boca arriba  <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20668/20668_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20669/20669_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20670/20670_lores.jpg']} /></>,
        <>Puede sostener un juguete y sacudirlo y golpear juguetes que estén colgando <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20666/20666_lores.jpg']} /></>,
        <>Se lleva las manos a la boca <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/MoD-xOT9ExI']} /></>,
        <>Cuando está boca abajo, levanta el cuerpo hasta apoyarse en los codos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20667/20667_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        'No sigue con la vista a las cosas que se mueven',
        'No le sonríe a las personas',
        'No puede sostener la cabeza con firmeza',
        'No gorjea ni hace sonidos con la boca',
        'No se lleva las cosas a la boca',
        'No empuja con los pies cuando le apoyan sobre una superficie dura',
        'Tiene dificultad para mover uno o los dos ojos en todas las direccione',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
