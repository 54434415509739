import React from 'react'

export const pageTitle = 'Derivación y Evaluación'
export const pageCopy = [
  {
    body: [
      "Si le preocupa el desarrollo de su hijo, o si usted o el médico de su hijo piensan que su hijo puede tener un retrasao, solicite una derivación a un especialista que pueda hacer una evaluación más profunda de su hijo.",
      "El médico de su hijo puede derivarle a:",
      [
        "Pediatras del desarrollo. Estos médicos tienen entrenamiento especializado en el desarrollo de niño y en niños con necesidades especiales.",
        "Neurólogos de niño. Estos médicos trabajan en el cerebro, la columna vertebral y los nervios.",
        "Psicólogos de niños o psiquiatras. Estos médicos saben acerca de la mente humana.",
      ],
      "Además, usted querrá preguntar el médico de su hijo para una referencia para una evaluación de intervención temprana.",
      <>
        También puede llamar a la línea directa de Strong Start Child Find al (202) 727-3665 o completar el <a href='https://eip.osse.dc.gov/ReferralForm/Create#googtrans(en|es)'>formulario de referencia</a> para niños de 0 a 2 años, 8 meses o enviar un <a href='https://www.earlystagesdc.org/Spanish'>formulario de referencia</a> a Early Stages para niños de 2 años, 8 meses a 5 años, 10 meses.  Tenga en cuenta que el proceso de evaluación de educación especial solo comenzará después de que un padre / tutor haya dado su consentimiento por escrito.
      </>
    ]
  },
  {
    title: '¿Qué es una evaluación del desarrollo?',
    body: [
      'Una evaluación del desarrollo analiza profundamente el desarrollo de un niño y puede identificar y diagnosticar retrasos y afecciones del desarrollo.'
    ]
  },
  {
    title: '¿Quién hace una evaluación del desarrollo?',
    body: [
      'Una evaluación del desarrollo generalmente es realizada por un especialista, como un pediatra del desarrollo, psicólogo de niño, patólogo del habla y el lenguaje, terapeuta ocupacional u otro especialista.'
    ]
  },
  {
    title: '¿Qué sucede durante una evaluación del desarrollo?',
    body: [
      "El especialista puede observar a su hijo, administrarle una prueba estructurada, entrevistarla y/o pedirle que complete cuestionarios." ,
      "Los resultados de esta evaluación formal determinan si su hijo necesita tratamiento específico, servicios de intervención temprana o ambos.",
      "Debe mantener registros de conversaciones y comunicaciones con proveedores y agencias.",
    ]
  },
  {
    title: 'Una nota sobre la privacidad:',
    body: [
      "A medida que navega por estos sistemas, es posible que tenga inquietudes con respecto a la privacidad de su hijo.  La Ley de Derechos Educativos y Privacidad de la Familia (FERPA por sus siglas en inglés) y la Ley de Portabilidad y Responsabilidad del Seguro Médico de 1996 (HIPAA por sus siglas en inglés) se establecieron para proteger la privacidad de su hijo.  Obtenga más información a continuación:"
    ]
  },
  {
    title: 'Ley de Derechos Educativos y Privacidad de la Familia (FERPA)',
    body: [
      <>
        La Ley de Derechos Educativos y Privacidad de la Familia (FERPA) otorga ciertos derechos a los padres con respecto a los registros educativos de sus hijos.  FERPA es una ley federal que es administrada por la Oficina de Política de Privacidad Estudiantil (SPPO) en el Departamento de Educación de los Estados Unidos (Departamento). FERPA protege los "registros de educación", que generalmente se definen como registros que están directamente relacionados con un estudiante y mantenidos por una agencia o institución educativa o por una parte que actúa para la agencia o institución.  <a href='https://studentprivacy.ed.gov/sites/default/files/resource_document/file/Ferpa-for-parents-spanish.pdf'>La Guía General para Padres de FERPA</a>  está destinada a proporcionar a los padres información general sobre FERPA.
      </>
    ]
  },
  {
    title: 'Ley de Portabilidad y Responsabilidad del Seguro Médico de 1996 (HIPAA)',
    body: [
      <>
        La Ley de Portabilidad y Responsabilidad del Seguro Médico de 1996 (HIPAA) le otorga derechos sobre su información de salud, incluido el derecho a obtener una copia de su información, asegurarse de que sea correcta y saber quién la ha visto.  Puede obtener más información visitando la página web del <a href='https://www.hhs.gov/ocr/get-help-in-other-languages/spanish.html'>Departamento de Salud y Servicios Humanos de los Estados Unidos.</a>
      </>
    ]
  },
]
