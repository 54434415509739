import React, { useState, useContext, useEffect } from 'react'
import { useQueryParam, StringParam } from "use-query-params";
import { Link } from 'gatsby'
import newLogo from '../images/newLogo.svg'
import { GlobalContext } from './GlobalContext'

const URLS = {
  'en': {
    'monitoring': {
      'title': 'Monitoring',
      'links': {
        'Developmental Monitoring': '/monitoring',
        'Milestones': '/milestones'
      }
    },
    'language': {
      'title': 'Language',
      'English': 'English',
      'Spanish': 'Spanish',
    },
    'main_level': {
      'Screening': '/screening',
      'Referral and Evaluation': '/evaluations',
      'Early Intervention': '/early_intervention_services',
      'Parent Resources': '/parent_resources',
      'Autism Resources': '/autism_resources',
      'FAQs': '/faqs',
      'About Us': '/about_us',
    }
  },
  'es': {
    'monitoring': {
      'title': 'El Monitoreo',
      'links': {
        'Monitoreo del desarrollo': '/monitoring',
        'Hitos': '/milestones',
      }
    },
    'language': {
      'title': 'Idioma',
      'English': 'Inglés',
      'Spanish': 'Español'
    },
      'main_level': {
        'Chequeo': '/screening',
        'Derivación y evaluación': '/evaluations',
        'Intervención Temprana': '/early_intervention_services',
        'Recursos': {
          'Para padres': '/parent_resources',
          'Sobre el Autismo': '/autism_resources',
        },
        'FAQs': '/faqs',
        'Sobre nosotros': '/about_us',
      }
  }
}

const Navbar = () => {
  const { setI18n, i18n } = useContext(GlobalContext)
  const [active, setActive] = useState(false)
  const [navBarActiveClass, setnavBarActiveClass] = useState('')
  const [language, setLanguage] = useQueryParam("language", StringParam);

  const setGlobalLanguage = (value) => {
    setLanguage(value)
    setI18n(value)
  }

  useEffect(() => {
    setGlobalLanguage(language || i18n)
  }, [])

  useEffect(() => {
    if (active) {
      setnavBarActiveClass('is-active')
    } else {
      setnavBarActiveClass('')
    }
  }, [active])

  const toggleHamburger = () => setActive(!active)

  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to={`/?language=${i18n}`} className="navbar-item" title="Logo" style={{ paddingTop: '4px' }}>
            <img src={newLogo} alt="DCAP" />
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${navBarActiveClass}`}
        >
          <div className="navbar-start has-text-centered">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                {URLS[i18n]['monitoring']['title']}
              </a>
              <div className="navbar-dropdown">
                {Object.entries(URLS[i18n]['monitoring']['links']).map(([name, url], index) =>
                  <Link key={index} className="navbar-item" to={`${url}?language=${i18n}`}>
                    {name}
                  </Link>
                )}
                </div>
              </div>
            {
              Object.entries(URLS[i18n]['main_level']).map(([name, url], index) => {
                if (typeof(url) === 'string') {
                  return (
                    <Link key={index} className="navbar-item" to={`${url}?language=${i18n}`}>
                      {name}
                    </Link>
                  )
                } else {
                  return (
                    <div className="navbar-item has-dropdown is-hoverable">
                      <a className="navbar-link">
                        {name}
                      </a>
                      <div className="navbar-dropdown">
                        {Object.entries(url).map(([name, url], index) =>
                          <Link key={index} className="navbar-item" to={`${url}?language=${i18n}`}>
                            {name}
                          </Link>
                        )}
                      </div>
                    </div>
                  )
                }
              })
            }
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                {URLS[i18n]['language']['title']}
              </a>
              <div className="navbar-dropdown">
                <a className="navbar-item" onClick={() => setGlobalLanguage('en')}>
                  {URLS[i18n]['language']['English']}
                </a>
                <a className="navbar-item" onClick={() => setGlobalLanguage('es')}>
                  {URLS[i18n]['language']['Spanish']}
                </a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
