import React, { useContext } from 'react'
import SimplePage from '../components/SimplePage'
import * as en from '../locales/en/monitoring'
import * as es from '../locales/es/monitoring'
import { GlobalContext } from '../components/GlobalContext'

const Monitoring = ({ }) => {
  const { i18n } = useContext(GlobalContext)

  return (
    <SimplePage
      title={i18n === 'en' ? en.pageTitle : es.pageTitle}
      copy={i18n === 'en' ? en.pageCopy : es.pageCopy}
    />
  )
}

export default Monitoring
