import React from 'react'

export const pageTitle = 'FAQs'
export const pageCopy = [
  {
    title: 'What is developmental monitoring and screening?',
    body: [
      "A child’s growth and development are followed through a partnership between parents and healthcare professionals. At each visit, the doctor looks for developmental delays or problems and talks with the parents about any concerns the parents might have. This is called developmental monitoring.",
      "Any problems noticed during developmental monitoring should be followed up with developmental screening. Developmental screening is a short test to tell if a child is learning basic skills when he or she should, or if there are delays.  Developmental screening can be done by a doctor or nurse, but also by other professionals in healthcare, early childhood education, community, or school settings.",
      "If a child has a developmental delay, it is important to get help as soon as possible. Early identification and intervention can have a significant impact on a child’s ability to learn new skills, as well as reduce the need for costly interventions over time.",
      <>
        <a href='https://www.cdc.gov/ActEarly'>Content adapted from “Learn the Signs. Act Early.” Program, Centers for Disease Control and Prevention</a>
      </>
    ]
  },
  {
    title: 'Why is early identification and intervention important?',
    body: [
      "According to the Centers for Disease Control and Prevention, about one in six children have some form of developmental disability. This includes anything from cerebral palsy to autism spectrum disorder to a minor speech impairment.  Many children with a developmental disability are not identified until after starting school.  Early intervention (before school age) can have a big impact on a child’s ability to learn new skills as well as reduce the need for costly interventions over time.",
      <>
        <a href='https://www.cdc.gov/ActEarly'>Content adapted from “Learn the Signs. Act Early.” Program, Centers for Disease Control and Prevention</a>
      </>
    ]
  },
  {
    title: 'What are developmental disabilities?',
    body: [
      "Developmental disabilities are a group of conditions due to an impairment in physical, learning, language, or behavior areas. These conditions begin during the developmental period, may impact day-to-day functioning, and usually last throughout a person’s lifetime.",
      <>
        <a href='https://www.cdc.gov/ncbddd/developmentaldisabilities/facts.html'>Content adapted from “Developmental Disabilities.” Centers for Disease Control and Prevention</a>
      </>
    ]
  },
  {
    title: 'What is Autism?',
    body: [
      <>
        Autism is a neurodevelopmental disability that can be associated with social, communication, and behavioral challenges. Children diagnosed with autism may communicate, interact, behave, and learn in ways that are different from people without autism. You can visit the <a href='https://www.cdc.gov/ncbddd/autism/signs.html'>Centers for Disease Control and Prevention</a> autism webpage to learn more about how autism is defined.
      </>
    ]
  },
  {
    title: "What happens after my child is diagnosed?",
    body: [
      "Research shows that early intervention treatment services can improve a child’s development and ability to learn new skills. Services can include different types of therapies to help with talking, walking, and interacting with others. It is important to talk to your child’s doctor as soon as possible if you have a concern for their development.",
      <>
      Your child may qualify for early intervention treatment services even if they don’t have an Autism Spectrum Disorder or diagnosis beyond developmental delay. This is due to the <a href='http://idea.ed.gov/'>Individuals with Disabilities Education Act (IDEA)</a> which says that children under the age of 3 years who show signs of potential developmental delays may be eligible for services. Early Intervention services are provided through <a href='https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip'>DC’s Strong Start Early Intervention Program</a>.
      </>,
      <>
        <a href='http://www.cdc.gov/ncbddd/autism/facts.html'>Content adapted from “Autism.” Centers for Disease Control and Prevention</a>
      </>
    ]
  },
  {
    title: "What is Act Early DC?",
    body: [
      "Act Early DC is a public service campaign to support and collaborate with DC early childhood programs that support families and child development to advance developmental monitoring and support developmental screening.  We do not provide services but work in the DC area to deliver the Centers for Disease Control and Prevention’s “Learn the Signs. Act Early.” campaign to educate families and professionals about developmental milestones and the importance of developmental screening and acting when there is a concern with a baby, toddler or child’s development."
    ]
  }
]
