import oneMonth from '/src/images/1_month.jpeg';
import twoMonth from '/src/images/2_months.jpeg';
import threeMonth from '/src/images/3_months.jpeg';
import fourMonth from '/src/images/4_months.jpeg';
import sixMonth from '/src/images/6_months.jpeg';
import nineMonth from '/src/images/9_months.jpeg';
import twelveMonth from '/src/images/12_months.jpeg';
import eighteenMonth from '/src/images/18_months.jpeg';
import twentyFourMonth from '/src/images/24_months.jpeg';
import thirtySixMonth from '/src/images/36_months.jpeg';
import fourtyEightMonth from '/src/images/48_months.jpeg';
import sixtyMonth from '/src/images/60_months.jpeg';

export const pageTitle = "Indicadores del desarrollo"
export const pageBody = 'Habilidades como dar los primeros pasos, sonreír por primera vez y agitar el “adiós” se llaman hitos del desarrollo. Los niños alcanzan hitos en la forma en que juegan, aprenden, hablan, actúan y se mueven (como gatear, caminar, etc.).'
export const BabyImageLink = [
  {
    linkTo: '/milestones/1_month',
    imageSrc: oneMonth,
    header: '1 Mes',
  },
  {
    linkTo: '/milestones/2_months',
    imageSrc: twoMonth,
    header: '2 Meses',
  },
  {
    linkTo: '/milestones/3_months',
    imageSrc: threeMonth,
    header: '3 Meses',
  },
  {
    linkTo: '/milestones/4_months',
    imageSrc: fourMonth,
    header: '4 Meses',
  },
  {
    linkTo: '/milestones/6_months',
    imageSrc: sixMonth,
    header: '6 Meses',
  },
  {
    linkTo: '/milestones/9_months',
    imageSrc: nineMonth,
    header: '9 Meses',
  },
  {
    linkTo: '/milestones/12_months',
    imageSrc: twelveMonth,
    header: '1 Año',
  },
  {
    linkTo: '/milestones/18_months',
    imageSrc: eighteenMonth,
    header: '18 Meses',
  },
  {
    linkTo: '/milestones/2_years',
    imageSrc: twentyFourMonth,
    header: '2 Años',
  },
  {
    linkTo: '/milestones/3_years',
    imageSrc: thirtySixMonth,
    header: '3 Años',
  },
  {
    linkTo: '/milestones/4_years',
    imageSrc: fourtyEightMonth,
    header: '4 Años',
  },
  {
    linkTo: '/milestones/5_years',
    imageSrc: sixtyMonth,
    header: '5 Años',
  },
]
