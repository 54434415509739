import React, { useContext } from 'react'
import * as en from '../../locales/en/milestones/2_years'
import * as es from '../../locales/es/milestones/2_years'
import { SimplePage } from '../../components/SimplePage'
import { GlobalContext } from '../../components/GlobalContext'

const TwoYears = ({}) => {
  const { i18n } = useContext(GlobalContext)

  return (
    <SimplePage
      title={i18n === 'en' ? en.pageTitle : es.pageTitle}
      copy={i18n === 'en' ? en.pageCopy : es.pageCopy}
      ageInMonths={en.AGE_IN_MONTHS}
      cdcChecklistUrl='https://www.cdc.gov/ncbddd/actearly/milestones/checklist/2-Year-Checklist-form.html'
    />
  )
}

export default TwoYears
