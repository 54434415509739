import React, { useContext } from 'react'
import SimplePage from '../components/SimplePage'
import * as en from '/src/locales/en/about_us'
import * as es from '/src/locales/es/about_us'
import { GlobalContext } from '/src/components/GlobalContext'

const AboutUs = ({ }) => {
  const { i18n } = useContext(GlobalContext)
  const copy = i18n === 'en' ? en : es

  return (
    <SimplePage title={copy.pageTitle} copy={copy.pageCopy} />
  )
}

export default AboutUs
