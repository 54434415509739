import React, { useContext } from 'react'
import * as en from '/src/locales/en/milestones/3_months'
import * as es from '/src/locales/es/milestones/3_months'
import { SimplePage } from '../../components/SimplePage'
import { GlobalContext } from '/src/components/GlobalContext'

const ThreeMonths = ({}) => {
  const { i18n } = useContext(GlobalContext)
  const copy = i18n === 'en' ? en : es

  return (
    <SimplePage
      title={copy.pageTitle}
      copy={copy.pageCopy}
      ageInMonths={copy.AGE_IN_MONTHS}
    />
  )
}

export default ThreeMonths
