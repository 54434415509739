import React from 'react'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 3
export const pageTitle = 'Indicadores importantes: su bebé a los 3 meses'
export const pageCopy = [
  {
    body: [
      <>
        Los hitos siguientes fueron desarrollados por la <a href='https://www.healthychildren.org/english/ages-stages/baby/pages/developmental-milestones-1-month.aspx'>American Academy of Pediatrics</a>.
      </>
    ]
  },
  {
    title: 'Hitos de movimiento',
    body: [
      [
        'Levanta su cabeza y tórax mientras está acostado boca abajo',
        'Sostiene su torso con sus brazos cuando está boca abajo',
        'Estira sus piernas y patea cuando está acostado boca abajo o boca arriba',
        'Abre y cierra sus manos',
        'Empuja sus piernas hacia abajo cuando está de pie en una superficie firme',
        'Lleva su mano a la boca',
        'Intenta pegarle con sus manos a los objetos que cuelgan',
        'Agarra y agita los juguetes en sus manos',
      ]
    ]
  },
  {
    title: 'Hitos visuales y auditivos',
    body: [
      [
        'Observa los rostros atentamente',
        'Sigue los objetos en movimiento',
        'Reconoce los objetos y personas familiares a cierta distancia',
        'Empieza a utilizar sus manos y ojos en coordinación',
        'Sonríe al sonido de su voz',
        'Empieza a balbucear',
        'Empieza a imitar algunos sonidos',
        'Voltea la cabeza hacia la dirección del sonido',
      ]
    ]
  },
  {
    title: 'Hitos sociales y emocionales',
    body: [
      [
        'Empieza a desarrollar una sonrisa social',
        'Disfruta jugar con otras personas y es posible que llore cuando deja de hacerlo',
        'Es más comunicativo y expresivo con su rostro y cuerpo',
        'Imita algunos movimientos y expresiones faciales',
      ]
    ]
  },
  {
  title: 'Supervisión de la salud en el desarrollo',
  body: [
    'Aunque cada bebé se desarrolla en una individual y a su propio ritmo, no alcanzar ciertos logros importantes puede ser señal de problemas médicos o de desarrollo que requieren atención especial. Si nota cualquiera de las siguientes señales de advertencia en su bebé a esta edad, discútalos con su pediatra.',
    [
      'No parece responder a los sonidos fuertes',
      'No observa sus manos a los dos meses',
      'No sonríe al sonido de su voz a los dos meses',
      'No sigue los objetos en movimiento con sus ojos a los dos o tres meses de edad',
      'No agarra ni sostiene los objetos a los tres meses',
      'No se sonríe con las personas a los tres meses',
      'No sostiene su cabeza adecuadamente a los tres meses',
      'No alcanza ni agarra los juguetes a los tres o cuatro meses',
      'No balbucea a los tres o cuatro meses',
      'No lleva los objetos a su boca a los cuatro meses',
      'Empieza a balbucear, pero no intenta imitar ninguno de sus sonidos a los cuatro meses',
      'No empuja sus piernas hacia abajo cuando está de pie en una superficie firme a los cuatro meses',
      'Tiene problemas para mover uno o ambos ojos en todas las direcciones',
      'Cruza sus ojos la mayor parte del tiempo (que cruce los ojos ocasionalmente es normal en los primeros meses).',
      'No presta atención a nuevos rostros ni parece muy asustado con rostros o entornos nuevos',
      'Aún tiene el reflejo tónico del cuello a los cuatro o cinco meses',
    ]
  ]
},
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS)
]
