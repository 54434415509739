import React from 'react'
import { positiveParentingTips, healthyBodies, childSafetyFirst } from './shared'

export const AGE_IN_MONTHS = 1
export const pageTitle = 'Important Milestones: Your baby by One Month'
export const pageCopy = [
  {
    body: [
      <>
        The following milestones were developed by the <a href='https://www.healthychildren.org/english/ages-stages/baby/pages/developmental-milestones-1-month.aspx'>American Academy of Pediatrics</a>.
      </>
    ]
  },
  {
    title: 'Movement Milestones',
    body: [
      [
        'Makes jerky, quivering arm thrusts',
        'Brings hands within range of eyes and mouth',
        'Moves head from side to side while lying on stomach',
        'Head flops backward if unsupported',
        'Keeps hands in tight fists',
        'Strong reflex movements',
      ]
    ]
  },
  {
    title: 'Visual and Hearing Milestones',
    body: [
      [
        "Focuses 8 to 12 inches (20.3 to 30.4 cm) away",
        "Eyes wander and occasionally cross",
        "Prefers black-and-white or high-contrast patterns",
        "Prefers the human face to all other patterns",
        "Hearing is fully mature",
        "Recognizes some sounds",
        "May turn toward familiar sounds and voices",
      ]
    ]
  },
  {
    title: 'Smell and Touch Milestones',
    body: [
      [
        'Prefers sweet smells',
        'Avoids bitter or acidic smells',
        'Recognizes the scent of his/her own mother’s breast milk',
        'Prefers soft to coarse sensations',
        'Dislikes rough or abrupt handling',
      ]
    ]
  },
  {
    title: 'Developmental Health Watch',
    body: [
      'If, during the second, third, or fourth weeks of your baby’s life, he/she shows any of the following signs of developmental delay, notify your pediatrician.',
      [
        'Sucks poorly and feeds slowly',
        'Doesn’t blink when shown a bright light',
        'Doesn’t focus and follow a nearby object moving side to side',
        'Rarely moves arms and legs; seems stiff',
        'Seems excessively loose in the limbs, or floppy',
        'Lower jaw trembles constantly, even when not crying or excited',
        'Doesn’t respond to loud sounds',
      ]
    ]
  },
  positiveParentingTips(1),
  childSafetyFirst(1),
  healthyBodies(1),
]
