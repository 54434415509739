import React from 'react'
import referralAndEvaluation from '/src/images/referral_and_evaluation.jpeg'
import developmentalScreening from '/src/images/developmental_screening.jpeg'
import freeMaterials from '/src/images/free_materials_es.jpeg'
import developmentalMonitoring from '/src/images/developmental_monitoring.jpeg'
import developmentalMilestones from '/src/images/developmental_milestones.jpeg'
import autismResources from '/src/images/autism_resources.jpeg'
import earlyIntervention from '/src/images/early_intervention.jpeg'
import milestonesApp from '/src/images/milestones_app.jpeg'
import toolkitCard from '/src/images/toolkit_card.jpeg'
import virtualEvent from '/src/images/virtual_event.jpeg'

export const pageTitle = 'Aprendé los signos. Reaccione pronto.'
export const paragraphs = [
  "Como madre o padre, usted ya tiene lo que necesita para ayudar a sus niños pequeños a aprender y crecer. Vea el progreso increíble de su hijo haciéndoles seguimiento a los indicadores del desarrollo desde el nacimiento hasta los cinco años —cómo juega, aprende, habla, actúa y se mueve— y comparta esa información con el médico de su hijo en cada una de las visitas de chequeo – y actúe pronto si tiene alguna inquietud.",
  <>
  El programa de los CDC <a href='https://www.cdc.gov/ncbddd/spanish/actearly/index.html'>“Aprenda los Signos. Reaccione Pronto.”</a> apunta a mejorar la identificación temprana de los niños con autismo y otras discapacidades del desarrollo para que los niños y las familias puedan recibir los servicios y el apoyo que necesitan.
  </>
]

export const cards = [
  {
    header: 'El monitoreo del desarrollo',
    content: 'En el monitoreo del desarrollo se observa cómo su hijo crece, cambia con el tiempo, y si su niño está alcanzando los indicadores típicos del desarrollo al jugar, aprender, hablar, actuar y moverse.',
    path: '/monitoring',
    imageSrc: developmentalMonitoring 
  },
  {
    header: 'Indicadores del desarrollo',
    content: 'Dar los primeros pasos, sonreír por primera vez y decir “adiós” con la mano son destrezas a las que se denomina indicadores del desarrollo. Los niños alcanzan estos indicadores en la forma en que juegan, aprenden, hablan, actúan y se mueven (como gatear, caminar, etc.).',
    path: '/milestones',
    imageSrc: developmentalMilestones
  },
  {
    // update this iamge
    header: 'La aplicación para seguir los indicadores del desarrollo',
    content: '¡Los indicadores del desarrollo son importantes! Siga el desarollo de su hijo desde los 2 meses hasta los cinco años con las listas ilustradas y fáciles de usar del CDC; obtenga consejos del CDC para fomentar el desarrollo de su hijo y averiguar que hacer si tiene preocupaciones sobre el desarrollo de su hijo',
    url: 'https://www.cdc.gov/ncbddd/spanish/actearly/spanish-milestones-app.html',
    imageSrc: milestonesApp
  },
  {
    header: 'Materiales gratis',
    content: 'El CDC ha creado materiales gratis para que los padres pueda seguir el desarrollo de su hijo. Los materiales incluyen los libros, listas, gráficos, y consejos sobre cómo obtener ayuda para su hijo, y más.',
    url: 'https://www.cdc.gov/ncbddd/spanish/actearly/materialesgratuitos.html',
    imageSrc: freeMaterials
  },
  {
    header: 'Las Pruebas del Desarrollo',
    content: 'Para saber si su hijo necesita más ayuda con su desarrollo, su hijo obtendrá un exámen breve, o completará usted un cuestionario sobre su hijo.',
    path: '/screening',
    imageSrc: developmentalScreening
  },
  {
    header: 'Recomendación y Evaluacion',
    content: 'Si las herramientas identifican alguna preocupación, usted necesitará ser referido a una especialista para saber si su hijo necesita un tratamiento específico o si califica para la intervención temprana',
    path: '/evaluations',
    imageSrc: referralAndEvaluation 
  },
  {
    header: 'Servicios de Intervención Temprana',
    content: 'Los servicios y los apoyos están disponibles para los bebés y niños pequeños con retrasos en el desarrollo,  discapacidades y sus familias.',
    path: '/early_intervention_services',
    imageSrc: earlyIntervention 
  },
  {
    header: 'Recursos para el Autismo',
    content: 'Si sospecha que su hijo puede tener un trastorno del espectro autista (TEA) o es recientemente diagnosticado con TEA, consulte estos recursos.',
    path: '/autism_resources',
    imageSrc: autismResources
  },
  // {
  //   header: 'Botiquín de herramientas para padres de Act Early DC',
  //   content: 'The Act Early DC Parent Toolkit provides tips on how families can support their young children during the COVID-19 Pandemic and beyond.',
  //   path: '/autism_resources',
  //   imageSrc: toolkitCard
  // },
  // {
  //   header: 'Evento Familiar Virtual de Act Early DC',
  //   content: 'In May 2021, the Act Early DC COVID-19 Response Team hosted a virtual event for families and caregivers of children ages 0-5 years to support their child’s development and self-care for themselves. Check out the session here!',
  //   url: 'https://www.youtube.com/playlist?list=PLEhZQU_WEgEu_iLBwqugcpp6obfDbjqjF',
  //   imageSrc: virtualEvent
  // },
]
