import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
} from './shared'
export const AGE_IN_MONTHS = 2
export const pageTitle = 'Important Milestones: Your baby by Two Months'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 2 months by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Begins to smile at people <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20651/20651_lores.jpg']} /></>,
        <>Can briefly calm herself (may bring hands to mouth and suck on hand) <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20652/20652_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20653/20653_lores.jpg']} /></>,
        <>Tries to look at parent <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20654/20654_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Coos, makes gurgling sounds <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/ktGf_CSVCVk']} /></>,
        <>Turns head toward sounds <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/7LNUNCfvwKk']} /></>
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Pays attention to faces <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20655/20655_lores.jpg']} /></>,
        <>Begins to follow things with eyes and recognize people at a distance <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20656/20656_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20657/20657_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20658/20658_lores.jpg']} /></>,
        <>Begins to act bored (cries, fussy) if activity doesn’t change <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/HJS68Ls9rB8']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Can hold head up and begins to push up when lying on tummy <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20659/20659_lores.jpg']} /></>,
        <>Makes smoother movements with arms and legs <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/uFLYxxEGPgs']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        'Doesn’t respond to loud sounds',
        'Doesn’t watch things as they move',
        'Doesn’t smile at people',
        'Doesn’t bring hands to mouth',
        'Can’t hold head up when pushing up when on tummy',
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
