import React from 'react'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

export const Modal = ({ state, toggleModal, resources, icon }) => {
  if (state === isInactive) {
    return null
  } else {
    return (
      <div className={`modal ${state}`}>
        <div onClick={toggleModal} className="modal-background"></div>
        <div className="modal-content">
          {icon === faCamera ?
              resources.map(image => {
                return (
                  <p className="image">
                    <img src={image} alt="" />
                  </p>
                )}) :
            <div className='iframe-container'>
              <iframe
                className='iframe'
                src={state === isActive ? resources[0] : null}
                frameBorder="0"
                allow="autoplay fullscreen"
                allowFullScreen
              />
            </div>
          }
        </div>
        <button onClick={toggleModal} className="modal-close is-large" aria-label="close"></button>
      </div>
    )
  }
}

export const isActive = 'is-active'
export const isInactive = 'is-inactive'
