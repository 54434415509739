import React from 'react'

export const pageTitle = 'Servicios de Intervención Temprana'
export const pageCopy = [
  {
    body: [
      'La investigación muestra que los servicios de la intervención temprana pueden mejorar en gran medida el desarrollo  de un niño.',
      [

        'Los servicios de intervención temprana ayuda a los niños desde el nacimiento hasta los 3 años de edad (36 meses) a aprender habilidades importantes.',
        'Para los niños de 3 años o más con un retraso on discapacidad del desarrollo identificado , es posible que se necesiten servicios de educación especial.',
      ],
      "Los servicios pueden incluir una variedad de opciones, depende de lo que necesita el niño, como la terapia para ayudar al niño a hablar, mover y caminar, y interaccionar con otros niños.",
      <>
        Los programas de Child Find son proveídos por cada estado para evaluar y identificar a los niños que necesitan servicios de educación especial. Los programas de Child Find del Districto de Columbia son de <a href='https://osse.dc.gov/node/1269736'>Strong Start DC Early Intervention Programa</a> y <a href='https://osse.dc.gov/node/1269736'>Early Stages</a>. El programa de Strong Start Early Intervention sirve infantes y niños pequeños (Nacimiento hasta los 3 años). Early Stages sirve los niños entre 2 años, 8 meses y 5 años, 10 meses.
      </>,
        "La ley de Educación para Individuos con Discapcaidades (IDEA por sus siglas en inglés) dice que los niños con un descapacidad diagnosticada deben recibir los servicios de la educación especial. IDEA dice que los niños menores qe 3 años que tienen riesgo de una descapacidad pueden ser elegibles para los servicios de la intervención temprana aún si el niño no ha recibido un diagnóstico formal. El tratamiento para los síntomas, como la terapia del habla para los retrasos en el lenguaje, puede no requerir un diagnóstico formal.",
      <>
        Aunque la intervención temprana es muy importante, la intervención a cada edad puede ser útil. Es mejor recibir una evalución temprano para iniciar las intervenciones necesarias. Cuando los padres tienen preocupaciones sobre el desarrollo de un niño, puede ser difícil encontrar los pasos correctos. Hay organizaciones para ayudar a los padres. “Parent Training and Information Center, <a href='https://www.aje-dc.org/'>Advocates for Justice and Education</a>" ayuda a las familias aprender cómo y adónde recibir las evaluaciones y los servicios.
      </>
    ]
  }
]
