import React from "react";

import holidayCalm from "../../images/es_holiday_calm.jpg";

export const pageTitle = "Enlaces a otros sitios web útiles para los padres";
export const pageCopy = [
  {
    body: [
      <a href="https://www.dropbox.com/s/02ib7v63vwu421s/SPANISH%20Tips%20to%20Help%20Parents%20Keep%20Calm%20During%20the%20Holidays%20%283%29.pdf?dl=0">
        Consejos para ayudar a los padres a mantener la calma durante las
        fiestas
      </a>,
      <div className="is-flex is-flex-direction-row is-justify-content-space-evenly container">
        <div style={{ maxWidth: "250px" }}>
          <figure className="image">
            <img src={holidayCalm} alt="DCAP toolkit image" />
          </figure>
        </div>
      </div>,
      <a href="https://www.cdc.gov/nutrition/infantandtoddlernutrition/index-es.html">
        Nutrición para bebés y niños pequeños
      </a>,
      "El sitio web de nutrición para bebés y niños pequeños de los Centros para el Control y la Prevención de Enfermedades combina información y estrategias prácticas sobre como alimentar a los bebés y niños pequeños desde el nacimiento hasta los 24 meses de edad con alimentos y bebidas saludables. Los padres y cuidadores pueden explorar estas páginas para encontrar información nutricional que ayude a dar a sus hijos un comienzo saludable en la vida.",
      <a href="HealthyChildren.org">HealthyChildren.org</a>,
      "HealthyChildren.org es un sitio web para padres, respaldado por pediatras, que provee información y recomendaciones relacionadas con la salud infantil, orientación sobre temas de la crianza y más; De la Academia Estadounidense de Pediatría.",
      <a href="https://jitp.info/">Just in Time Parenting (JITP)</a>,
      "Just in Time Parenting (Crianza a tiempo) cuenta con muchos recursos que brindan a los padres información que necesitan para apoyar y guiar el crecimiento de sus hijos.",
      <a href="https://www.cdc.gov/ncbddd/spanish/childdevelopment/positiveparenting/index.html">
        Consejos de crianza positiva
      </a>,
      "A medida que su hijo va creciendo y se va desarrollando, hay muchas cosas que puede hacer para ayudarlo. Estos enlaces lo ayudarán a aprender más sobre la crianza positiva, la seguridad y la salud en cada etapa de la vida del niño.",
      "Obtenga consejos de crianza positiva y seguridad del niño según los grupos de edades indicados a continuación.",
      [
        <a href="https://www.cdc.gov/ncbddd/spanish/childdevelopment/positiveparenting/infants.html">
          Bebés de 0 a 1 año
        </a>,
        <a href="https://www.cdc.gov/ncbddd/spanish/childdevelopment/positiveparenting/toddlers.html">
          Niños pequeños de 1 a 2 años
        </a>,
        <a href="https://www.cdc.gov/ncbddd/spanish/childdevelopment/positiveparenting/toddlers2.html">
          Niños pequeños de 2 a 3 años
        </a>,
        <a href="https://www.cdc.gov/ncbddd/spanish/childdevelopment/positiveparenting/preschoolers.html">
          Niños en edad preescolar de 3 a 5 años
        </a>,
      ],
      <a href="https://www.healthychildren.org/spanish/motordelay/paginas/default.aspx#/">
        Retrasos en el Desarrollo Físico: Que Buscar
      </a>,
      "La herramienta Los Retrasos en el Desarrollo es para los padres preocupados por el desarrollo físico de sus hijos. La herramienta refuerza que los padres conocen mejor a sus hijos y deben confiar en sus instintos.",
    ],
  },
  {
    title: "Recursos para ayudar a promover el desarrollo de su niño",
    body: [
      <a href="http://talkingisteaching.org/resources/developmental-milestones-handout">
        Talking is Teaching: Talk, Read, Sing
      </a>,
      "“Talking is Teaching: Talk, Read, Sing” (Hablar es enseñar: hable, lea, cante) es una campaña de concientización y acción que ayuda a los padres a reconocer el poder que tienen para estimular el desarrollo temprano del cerebro y del vocabulario mediante actos simples que pueden hacer a diario.",
      <a href="https://www.vroom.org/">Vroom</a>,
      "Vroom tiene consejos para mejorar la capacidad del cerebro con el fin de apoyar el desarrollo de su hijo. Use estos consejos con su niño durante los primeros 5 años, lo cual contribuirá a darle una base sólida para el aprendizaje a lo largo de toda la vida.",
      [
        <>
          Descargue el afiche{" "}
          <a href="https://www.cdc.gov/ncbddd/actearly/pdf/vroom/Vroom-Poster_SPAN_14x8.5_FNL-508.pdf">
            ¡Los indicadores del desarrollo son importantes con los CDC y Vroom!
          </a>{" "}
          (Milestones Matter with CDC and Vroom!) como recurso útil para hacerle
          seguimiento al desarrollo de su hijo y aprender cómo mejorar la
          capacidad del cerebro.
        </>,
      ],
      <a href="https://www.zerotothree.org/early-development">Zero to Three</a>,
      "Zero to Three (De Cero a Tres) tiene información y herramientas basadas en datos científicos desiñadas a ayudar a los padres y cuidadores para promover el desarrollo de sus pequeños niños.",
    ],
  },
  // {
  //   title: 'Act Early DC Toolkit',
  //   body: [
  //     "The Act Early DC Parent Toolkit was designed to introduce families/caregivers to the five Strengthening Families Protective Factors and to provide tips on how families can support their young children during the COVID-19 Pandemic and beyond.",
  //     "Below please find the plain language and detailed toolkits."
  //   ]
  // },
];
