import React from 'react'

export const pageTitle = 'Early Intervention Services'
export const pageCopy = [
  {
    body: [
      'Research shows that early intervention treatment services can greatly improve a child’s development.',
      [
        "Early intervention services help children from birth through 3 years of age (36 months) learn important skills.",
        "For children age 3 and older with an identified developmental delay or disability, special education services may be needed."
      ],
      "Services can include a variety of options, depending on the child’s need, such as therapy to help the child talk, move and walk, learn, and interact with others.",
      <>
      Child Find programs are provided by each state to evaluate and identify children who need special education services. DC’s Child Find programs are <a href='https://osse.dc.gov/service/strong-start-dc-early-intervention-program-dc-eip'>Strong Start DC Early Intervention Program</a> and Early Stages. The Strong Start DC Early Intervention Program serves infants and toddlers (birth to 3 years of age). <a href='https://www.earlystagesdc.org/'>Early Stages</a> serves children between the ages of 2 years, 8 month and 5 years 10 months.
      </>,
      "The Individuals with Disabilities Education Act (IDEA) says that children with a diagnosed disability should get special education services. IDEA says that children younger than 3 years of age who are at risk of having developmental delays might be eligible for early intervention treatment services even if the child has not received a formal diagnosis. Treatment for symptoms, such as speech therapy for language delays, may not require a formal diagnosis.",
      <>
      Although early intervention is extremely important, intervention at any age can be helpful. It is best to get an evaluation early so that any needed interventions can get started. When parents are concerned about a child’s development, it can be challenging for them to figure out the right steps to take. To help, there are parent centers. DC’s Parent Training and Information Center, <a href='https://www.aje-dc.org/'>Advocates for Justice and Education</a>, helps families learn how and where to have their children evaluated and how to find services.
      </>
    ]
  }
]
