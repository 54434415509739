import React, { useContext } from 'react'
import { GlobalContext } from '/src/components/GlobalContext'
import { Link } from 'gatsby'

export const Card = ({ imageSrc, header, content, path, url }) => {
  const { i18n } = useContext(GlobalContext)
  const copy = i18n === 'en' ? 'Learn More' : 'Aprende Más'

  return (
    <div className="card mb-6 card-basis" style={{ height:  i18n === 'en' ? '520px' : '550px' }}>
      <div className="card-image">
        <figure className="image">
          <img src={imageSrc} alt="Placeholder image" />
        </figure>
      </div>
      <p className="card-header-title is-family-secondary has-text-primary">{header}</p>
      <div className="card-content">
        <div className="content">
          {content}
        </div>
      </div>
      <div className='columns is-centered'>
        <div className='column has-text-centered'>
          {path &&
              <Link to={`${path}?language=${i18n}`} className="button is-primary is-light">{copy}</Link>
          }
          {url &&
              <a href={url} target='_blank' className="button is-primary is-light">{copy}</a>
          }
        </div>
      </div>
    </div>
  )
}
