import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 9
export const pageTitle = 'Important Milestones: Your baby by Nine Months'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 9 months by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>May be afraid of strangers <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Puel3D6fMEA']} /></>,
        <>May be clingy with familiar adults <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/2ANlZWF8a1k']} /></>,
        <>Has favorite toys <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/0SeOY0q8Ii0']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Understands “no” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/OxzBuPAwt2E']} /></>,
        <>Makes a lot of different sounds like “mamamama” and “bababababa” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/JbwH30VV5VI']} /></>,
        <>Copies sounds and gestures of others <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20683/20683_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20684/20684_lores.jpg']} /></>,
        <>Uses fingers to point at things <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20685/20685_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Watches the path of something as it falls <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/UGJuozLLX9s']} /></>,
        <>Looks for things she sees you hide <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20686/20686_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20687/20687_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20688/20688_lores.jpg']} /></>,
        <>Plays peek-a-boo <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/cQJPM8gW8oU']} /></>,
        <>Puts things in his mouth <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20689/20689_lores.jpg']} /></>,
        <>Moves things smoothly from one hand to the other <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20690/20690_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20691/20691_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20693/20693_lores.jpg']} /></>,
        <>Picks up things like cereal o’s between thumb and index finger <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20694/20694_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Stands, holding on <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20695/20695_lores.jpg']} /></>,
        <>Can get into sitting position <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6QFwdkuYxdU']} /></>,
        <>Sits without support <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20696/20696_lores.jpg']} /></>,
        <>Pulls to stand <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20698/20698_lores.jpg']} /></>,
        <>Crawls <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/YlVDkF9WXLs']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        "Doesn’t bear weight on legs with support",
        "Doesn’t sit with help",
        "Doesn’t babble (“mama”, “baba”, “dada”)",
        "Doesn’t play any games involving back-and-forth play",
        "Doesn’t respond to own name",
        "Doesn’t seem to recognize familiar people",
        "Doesn’t look where you point",
        "Doesn’t transfer toys from one hand to the other",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
