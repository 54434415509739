import React from 'react'
import yetta from '../../images/yetta.jpg'
import alison from '../../images/alison.jpg'
// import SocialMediaLinks from '/src/components/SocialMediaLinks'

export const pageTitle = "Sobre nosotros"
export const pageCopy = [
  // {
  //   title: "Find Us On Social Media",
  //   body: [
  //     <>
  //     <div className='columns'>
  //       <SocialMediaLinks />
  //     </div>
  //     </>
  //   ]
  // },
  {
    title: "Conozca la Embajadora de DC Act Early",
    body: [
      <>
        <figure className="image" style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}>
          <img src={yetta} />
        </figure>
      </>,
      <>
        <b>Yetta Myrick</b> es la madre de un hijo adolescente diagnosticado con un trastorno del espectro autista y discapacidad intelectual. Ella es la fundadora y presidenta de DC Autism Parents (DCAP), una organización sin fines de lucro 501 (c) (3) en el Distrito de Columbia, donde supervisa las operaciones diarias y ha creado programas para niños y jóvenes diagnosticados con autismo y sus familias. La Sra. Myrick ha sido Embajadora de Act Early de la CDC en el Distrito de Columbia desde 2016 y actualmente lidera el Proyecto de Equipo de Respuesta al COVID-19 de DC Act Early. Dirige el Subgrupo de Monitoreo, Detección y Evaluación del Desarrollo de DC Autism Collaborative y sirve como Educadora de Padres / Defensora en el Equipo ECHO Autism HUB dentro de Children's National Hospital. La Sra. Myrick es la directora de Comunicación de Parents' Place of Maryland y codirige el Proyecto Family Voices Unidos para Terminar con el Racismo Contra CYSHCN y sus Familias. Tiene una Licenciatura en Estudios de Comunicación de la Universidad Católica de América. La Sra. Myrick fue coordinadora de alcance comunitario en el Centro de Trastornos del Espectro Autista (CASD por sus siglas en inglés) en Children's National de 2016 a 2020 y técnica de estudio en C-SPAN de 2005 a 2016. En 2019, fue nombrada por la alcaldesa de DC, Muriel Bowser, para servir como miembro del Consejo de Discapacidades del Desarrollo de DC. Además, la Sra. Myrick se desempeña como copresidenta del Consejo de Apoyo Familiar y es miembro del Grupo Asesor Nacional de Transición de Atención médica familiar de Got Transition®. Ella cree profundamente que la participación de los padres es clave para obtener servicios de calidad para todos los niños, y que un padre informado es un padre comprometido.
      </>
    ]
  },
  {
    title: 'Yetta Myrick actualmente lidera el Proyecto de Equipo de Respuesta al COVID-19 de DC Act Early ',
    body: []
  },
  {
    title: 'Socio estatal',
    body: [
      <>
        <figure className="image" style={{ width: '250px', marginLeft: 'auto', marginRight: 'auto' }}>
          <img src={alison} />
        </figure>
      </>,
      'Alison Whyte, Consejo de Discapacidades del Desarrollo de DC',
      <>
        Como hermana de una persona con una discapacidad en el desarrollo, <b>Alison Whyte</b> ha estado trabajando hacia la plena inclusión de las personas con discapacidades en el trabajo, la escuela y la vida comunitaria durante todo el tiempo que pueda recordar. Actualmente, Alison sirve a personas con discapacidades y sus familias en el Distrito de Columbia como Directora Ejecutiva del Consejo de Discapacidades del Desarrollo de DC. En este papel, Alison lidera los esfuerzos de base para construir una comunidad inclusiva en DC a través de la promoción de políticas y el desarrollo de programas que promuevan la autodeterminación, la integración y la justicia social. Alison también es una capacitadora certificada en el pensamiento centrado en la persona a través de la Comunidad de Aprendizaje para Prácticas Centradas en la Persona (TLCPCP por sus siglas en inglés) y miembro de la Comunidad de Práctica de Apoyo a las Familias. Alison tiene experiencias previas en investigación y análisis estadístico, creación de capacidad, promoción de políticas, desarrollo de liderazgo y creación de coaliciones con organizaciones como el Departamento de Servicios para Discapacitados de DC, The Arc of DC, Best Buddies, las Naciones Unidas, el Banco Mundial y el Foro de Liderazgo Juvenil de Montana. Además, Alison es miembro de la Junta Directiva de Art Enables, un estudio de arte en DC, y como secretaria de la Junta Directiva de TLCPCP.,
      </>,
      'Alison tiene una Maestría en Trabajo Social y Políticas Públicas de la Universidad de Michigan, donde se enfocó en la organización comunitaria y la defensa de políticas como estrategias para promover cambios sociales. A Alison le encantan los animales, estar al aire libre tanto como sea posible, andar en bicicleta por DC y practicar sus habilidades de lenguaje de español y lenguaje de señas americano.'
    ]
  },
  {
    title: 'Alison Whyte actualmente colida el Equipo de Respuesta al COVID-19 de DC Act Early',
    body: []
  },
  {
    title: 'El Equipo de Respuesta al COVID-19 de DC Act Early',
    body: [
      'El equipo Reaccione Pronto, que es respuesta de COVID-19, consiste de accionistas de las siguientes organizaciones y agencias gobernales:',
      [
        'Advocates for Justice and Education, Inc.',
        <>
        <a href='https://childrensnational.org/espanol'>Children’s National Hospital</a>
        </>,
        <>
        <a href='https://cfsa.dc.gov/page/cfsa-spanish'>La Agencia de Servicios a Niños y Familias</a>
        </>,
        'DC Developmental Disabilities Council',
        <>
        <a href='https://dchealth.dc.gov/Spanish%20%28Espa%C3%B1ol%29'>Departamento de Salud</a>
        </>,
        <>
        <a href='https://osse.dc.gov/node/1269736'>DC Strong Start</a>
        </>,
        'DC Chapter American Academy of Pediatrics',
        'DC Head Start Association',
        'DC Women, Infant, and Children (WIC) Program',
        <>
        <a href='https://www.earlystagesdc.org/Spanish'>Early Stages</a>
        </>,
        <>
        <a href='https://helpmegrow.dc.gov/es'>Help Me Grow DC</a>
        </>,
        'University Center for Excellence in Developmental Disability (UCEDD) at Georgetown University'
      ],
    ]
  }
]
