import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { GlobalContext } from './GlobalContext'

export const BabyImageLink = ({ imageSrc, header, linkTo }) => {
  const { i18n } = useContext(GlobalContext)

  return (
    <section className='section'>
      <div className='container pb-4' style={{ width: 256 }}>
        <Link to={`${linkTo}?language=${i18n}`}>
          <figure className="image">
            <img className='is-rounded' src={imageSrc} alt="Placeholder image" />
          </figure>
        </Link>
      </div>
      <h2 className="title is-4 has-text-centered">
        <Link to={`${linkTo}?language=${i18n}`}>{header}</Link>
      </h2>
    </section>
  )
}
