import React from 'react'
import actEarlyDcUnderConstruction from '../images/actEarlyDcUnderConstruction.jpg'
import dcLogo from '../images/act_early_dc.svg'
import toolkitPdf from '../images/toolkit.pdf'
import toolkitDetailedPdf from '../images/toolkit-detailed.pdf'
import toolkitPng from '../images/toolkit.png'

const UnderConstruction = ({ }) => (
  <section className='section container has-text-centered'>
    <div style={{ maxWidth: '500px', margin: 'auto' }}>
      <figure className="image is-3by-1">
        <img src={dcLogo} alt="Act Early DC Logo" />
      </figure>
    </div>
    <div style={{ maxWidth: '500px', margin: 'auto' }}>
      <figure className="image">
        <img src={actEarlyDcUnderConstruction} alt="actEarlyDc" />
      </figure>
    </div>
    <h3 className="pt-6 title has-text-primary">
      Under Construction
    </h3>
    <div
      style={{ maxWidth: '400px' }}
      className="is-flex is-flex-direction-row is-justify-content-space-evenly container"
    >
      <div style={{ maxWidth: '150px' }}>
        <figure className="image">
          <img src={toolkitPng} alt="DCAP toolkit image" />
        </figure>
      </div>
    </div>
    <a href={toolkitPdf}>Download Our Toolkit Here</a>
    <br />
    <a href={toolkitDetailedPdf}>Download Our Detailed Toolkit Here</a>
  </section>
)

export default UnderConstruction
