import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 36
export const pageTitle = 'Important Milestones: Your child by 3 Years'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 3 years by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Copies adults and friends <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20743/20743_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20744/20744_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20745/20745_lores.jpg']} /></>,
        <>Shows affection for friends without prompting <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20910/20910_lores.jpg']} /></>,
        <>Takes turns in games <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/c9KtszF2_oc']} /></>,
        <>Shows concern for crying friend <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20747/20747_lores.jpg']} /></>,
        <>Understands the idea of “mine” and “his” or “hers” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Om-zZvqXPlY']} /></>,
        <>Shows a wide range of emotions <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20746/20746_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20748/20748_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20749/20749_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20750/20750_lores.jpg']} /></>,
        <>Separates easily from mom and dad <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20751/20751_lores.jpg']} /></>,
        <>May get upset with major changes in routine <ExternalLink icon={faCamera} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/images/mayGetUpset.jpg']} /></>,
        <>Dresses and undresses self <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20752/20752_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20753/20753_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Follows instructions with 2 or 3 steps <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/-d6rgPOGnSQ']} /></>,
        <>Can name most familiar things <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/LY8Ot4DJjpU']} /></>,
        <>Understands words like “in,” “on,” and “under” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/xIqVMimUWN0']} /></>,
        <>Says first name, age, and sex <ExternalLink icon={faCamera} resources={['https://www.cdc.gov/ncbddd/actearly/milestones/photolibrary/images/says-first-name-age-and-sex-700px.jpg']} /></>,
        <>Names a friend <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/ogqu1lRL4j8']} /></>,
        <>Says words like “I,” “me,” “we,” and “you” and some plurals (cars, dogs, cats) <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/T4tybEWGaIg']} /></>,
        <>Talks well enough for strangers to understand most of the time <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/k0Hu0FOe5Pc']} /></>,
        <>Carries on a conversation using 2 to 3 sentences <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/dlFBFObuVlM']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Can work toys with buttons, levers, and moving parts <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20760/20760_lores.jpg']} /></>,
        <>Plays make-believe with dolls, animals, and people <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20754/20754_lores.jpg']} /></>,
        <>Does puzzles with 3 or 4 pieces <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20755/20755_lores.jpg']} /></>,
        <>Understands what “two” means <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/BUDDlzpgYc0']} /></>,
        <>Copies a circle with pencil or crayon <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20756/20756_lores.jpg']} /></>,
        <>Turns book pages one at a time <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20757/20757_lores.jpg']} /></>,
        <>Builds towers of more than 6 blocks <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20758/20758_lores.jpg']} /></>,
        <>Screws and unscrews jar lids or turns door handle <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20759/20759_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Climbs well <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20911/20911_lores.jpg']} /></>,
        <>Runs easily <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/wkOOxsIrwgM']} /></>,
        <>Pedals a tricycle (3-wheel bike) <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20761/20761_lores.jpg']} /></>,
        <>Walks up and down stairs, one foot on each step <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20763/20763_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        "Falls down a lot or has trouble with stairs",
        "Drools or has very unclear speech",
        "Can’t work simple toys (such as peg boards, simple puzzles, turning handle)",
        "Doesn’t speak in sentences",
        "Doesn’t understand simple instructions",
        "Doesn’t play pretend or make-believe",
        "Doesn’t want to play with other children or with toys",
        "Doesn’t make eye contact",
        "Loses skills he once had",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
