import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 12
export const pageTitle = 'Important Milestones: Your baby by One Year'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 1 year by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Is shy or nervous with strangers <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/rkC2Wf4A_nw']} /></>,
        <>Cries when mom or dad leaves <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/p86vpbe6cOE']} /></>,
        <>Has favorite things and people <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20700/20700_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20701/20701_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20702/20702_lores.jpg']} /></>,
        <>Shows fear in some situations <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/0h1OoeVTHJw']} /></>,
        <>Hands you a book when he wants to hear a story <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20703/20703_lores.jpg']} /></>,
        <>Repeats sounds or actions to get attention <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/HV-SB7iYQtM']} /></>,
        <>Puts out arm or leg to help with dressing <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20704/20704_lores.jpg']} /></>,
        <>Plays games such as “peek-a-boo” and “pat-a-cake” <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20706/20706_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20705/20705_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Responds to simple spoken requests <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/tzjCTwrlEqI']} /></>,
        <>Uses simple gestures, like shaking head “no” or waving “bye-bye” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/h1xwTJNV0rA']} /></>,
        <>Makes sounds with changes in tone (sounds more like speech) <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/5eceKJPl8UU']} /></>,
        <>Says “mama” and “dada” and exclamations like “uh-oh!” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/JiGnOH1GTdM']} /></>,
        <>Tries to say words you say <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/ANzafxAxLW0']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Explores things in different ways, like shaking, banging, throwing <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/pMVinh7qvT8']} /></>,
        <>Finds hidden things easily <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20707/20707_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20708/20708_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20709/20709_lores.jpg']} /></>,
        <>Looks at the right picture or thing when it’s named <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/rQ6RuauvK3Y']} /></>,
        <>Copies gestures <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20710/20710_lores.jpg']} /></>,
        <>Starts to use things correctly; for example, drinks from a cup, brushes hair <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/9nyrA0e9BGE']} /></>,
        <>Bangs two things together <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/UVoVM0k065Q']} /></>,
        <>Puts things in a container, takes things out of a container <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/gHYUR7nI8mI']} /></>,
        <>Lets things go without help <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/33E6N81FKDk']} /></>,
        <>Pokes with index (pointer) finger <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20711/20711_lores.jpg']} /></>,
        <>Follows simple directions like “pick up the toy” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/pFy0ZT7aLjk']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Gets to a sitting position without help <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20712/20712_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20713/20713_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20714/20714_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20715/20715_lores.jpg']} /></>,
        <>Pulls up to stand, walks holding on to furniture (“cruising”) <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/AcNzdSTBhHI']} /></>,
        <>May take a few steps without holding on <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/hrTXjv3TjX4']} /></>,
        <>May stand alone <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20716/20716_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        "Doesn’t crawl",
        "Can’t stand when supported",
        "Doesn’t search for things that she sees you hide",
        "Doesn’t say single words like “mama” or “dada”",
        "Doesn’t learn gestures like waving or shaking head",
        "Doesn’t point to things",
        "Loses skills he once had",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
