import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 9
export const pageTitle = 'Indicadores importantes: su bebé a los 9 meses'
export const pageCopy = [
  {
    title: 'Lo que la mayoría de los bebés hacen a esta edad',
    body: []
  },
  {
    title: 'Social y Emocional',
    body: [
      [
        <>Puede que les tenga miedo a los desconocidos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/Puel3D6fMEA']} /></>,
        <>Puede que se aferre a los adultos conocidos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/2ANlZWF8a1k']} /></>,
        <>Tiene juguetes preferidos  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/0SeOY0q8Ii0']} /></>,
      ]
    ]
  },
  {
    title: 'Lenguaje/Comunicación',
    body: [
      [
        <>Entiende cuando se le dice “no”  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/OxzBuPAwt2E']} /></>,
        <>Hace muchos sonidos diferentes como “mamamama”y “tatatatata”  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/JbwH30VV5VI']} /></>,
        <>Copia los sonidos y gestos que hacen otras personas <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20683/20683_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20684/20684_lores.jpg']} /></>,
        <>Señala objetos con los dedos <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20685/20685_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitivo (aprendizaje, razonamiento, resolución de problemas)',
    body: [
      [
        <>Observa el recorrido de una cosa cuando cae  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/UGJuozLLX9s']} /></>,
        <>Va en busca de las cosas que usted esconde <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20686/20686_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20687/20687_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20688/20688_lores.jpg']} /></>,
        <>Juega a ¿Dónde está el bebé? (cucú, “peek-a-boo”)  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/cQJPM8gW8oU']} /></>,
        <>Se pone las cosas en la boca <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20689/20689_lores.jpg']} /></>,
        <>Pasa objetos de una mano a la otra con facilidad <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20690/20690_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20691/20691_lores.jpg', 'http://phil.cdc.gov/PHIL_Images/20693/20693_lores.jpg']} /></>,
        <>Levanta cosas como cereales en forma de “o” entre el dedo índice y el pulgar <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20694/20694_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Movimiento/Desarrollo Físico',
    body: [
      <>Se para sosteniéndose en algo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20695/20695_lores.jpg']} /></>,
      <>Puede sentarse solo  <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6QFwdkuYxdU']} /></>,
      <>Se sienta sin apoyo <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20696/20696_lores.jpg']} /></>,
      <>Hala para ponerse de pie <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20698/20698_lores.jpg']} /></>,
      <>Gatea  <ExternalLink icon={faCamera} resources={['https://www.youtube.com/embed/YlVDkF9WXLs']} /></>,
    ]
  },
  {
    style: 'act_early_card',
    title: 'Actúe a tiempo y hable con el doctor si su hijo:',
    body: [
      [
        "No se sienta con ayuda",
        "No se sostiene en las piernas con apoyo",
        "No balbucea (“mama”, “baba”, “papa”)",
        "No juega a nada que sea por turnos como “me toca a mí, te toca a ti”",
        "No responde cuando le llaman por su nombre",
        "No parece reconocer a las personas conocidas",
        "No mira hacia donde usted señala",
        "No pasa juguetes de una mano a la otra",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
