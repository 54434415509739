import React from 'react'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { ExternalLink } from '../../../components/ExternalLink'
import {
  positiveParentingTips,
  healthyBodies,
  childSafetyFirst,
  concernedCard,
} from './shared'
export const AGE_IN_MONTHS = 18
export const pageTitle = 'Important Milestones: Your baby by 18 Months'
export const pageCopy = [
  {
    body: [
      <>
      Check the milestones your child has reached by the end of 18 months by completing a checklist with CDC’s free <a href ='http://www.cdc.gov/MilestoneTracker'>Milestone Tracker</a> mobile app, for <a href='https://itunes.apple.com/us/app/cdcs-milestone-tracker/id1232718688?mt=8'>ios</a> and <a href='https://play.google.com/store/apps/details?id=gov.cdc.ncbddd.actearly.milestones'>Android</a> devices, or by <a href='https://www.cdc.gov/ncbddd/actearly/pdf/checklists/CDC_-LTSAE-Checklists-with-Tips-2month-P.pdf'>printing the checklist</a> below. Take the checklist with you and talk with your child’s doctor at every visit about the milestones your child has reached and what to expect next.
      </>
    ]
  },
  {
    title: 'What most babies do by this age:',
    body: []
  },
  {
    title: 'Social and Emotional',
    body: [
      [
        <>Likes to hand things to others as play <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20717/20717_lores.jpg']} /></>,
        <>May have temper tantrums <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/iSKMkHKhocQ']} /></>,
        <>May be afraid of strangers <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/0bUa6AvfaeQ']} /></>,
        <>Shows affection to familiar people <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20721/20721_lores.jpg']} /></>,
        <>Plays simple pretend, such as feeding a doll <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6kszHh5F4fU']} /></>,
        <>May cling to caregivers in new situations <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20718/20718_lores.jpg']} /></>,
        <>Points to show others something interesting <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20719/20719_lores.jpg']} /></>,
        <>Explores alone but with parent close by <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20720/20720_lores.jpg']} /></>,
      ]
    ]
  },
  {
    title: 'Language/Communication',
    body: [
      [
        <>Says several single words <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/AMxgyI4EdSM']} /></>,
        <>Says and shakes head “no” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/az4jhcbsDms']} /></>,
        <>Points to show someone what he wants <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/nmkZphuIzvk']} /></>,
      ]
    ]
  },
  {
    title: 'Cognitive (learning, thinking, problem-solving)',
    body: [
      [
        <>Knows what ordinary things are for; for example, telephone, brush, spoon <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20722/20722_lores.jpg']} /></>,
        <>Points to get the attention of others <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20723/20723_lores.jpg']} /></>,
        <>Shows interest in a doll or stuffed animal by pretending to feed <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20724/20724_lores.jpg']} /></>,
        <>Points to one body part <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/RSynaBnWz4k']} /></>,
        <>Scribbles on his own <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20725/20725_lores.jpg']} /></>,
        <>Can follow 1-step verbal commands without any gestures; for example, sits when you say “sit down” <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/kL4-4Ls0W7Q']} /></>,
      ]
    ]
  },
  {
    title: 'Movement/Physical Development',
    body: [
      [
        <>Walks alone <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/JaOUmM5ItYU']} /></>,
        <>May walk up steps and run <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/pJ6lJT0OakY']} /></>,
        <>Pulls toys while walking <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/E5zpJPaxm18']} /></>,
        <>Can help undress herself <ExternalLink icon={faVideo} resources={['https://www.youtube.com/embed/6D4MyzTdyTE']} /></>,
        <>Drinks from a cup <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20727/20727_lores.jpg']} /></>,
        <>Eats with a spoon <ExternalLink icon={faCamera} resources={['http://phil.cdc.gov/PHIL_Images/20726/20726_lores.jpg']} /></>,
      ]
    ]
  },
  {
    style: 'act_early_card',
    title: 'Act early by talking to your child’s doctor if your child:',
    body: [
      [
        "Doesn’t point to show things to others",
        "Can’t walk",
        "Doesn’t know what familiar things are for",
        "Doesn’t copy others",
        "Doesn’t gain new words",
        "Doesn’t have at least 6 words",
        "Doesn’t notice or mind when a caregiver leaves or returns",
        "Loses skills he once had",
      ]
    ]
  },
  positiveParentingTips(AGE_IN_MONTHS),
  childSafetyFirst(AGE_IN_MONTHS),
  healthyBodies(AGE_IN_MONTHS),
]
